import React, {useEffect} from "react";
import FormLogin from "../../form/login/FormLogin";
import CalopinIcon from "../../icons/CalopinIcon";

const Login = () => {
    useEffect(() => {
        document.title = "Calopin - Content de vous revoir";
    }, []);

    return (
        <div className={"box"}>
            <CalopinIcon classname={"logo"} />
            <h1>Content de vous revoir.</h1>
            <p>Pour vous connecter, merci de saisir votre adresse email et votre mot de passe.</p>
            <FormLogin />
        </div>
    )
}

export default Login;