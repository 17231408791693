import Rest from "../../../class/tool/Rest"
import User from "../../_account/Users/User";
import Establishment from "../../_school/Establishments/Establishment";

class AuthController {
    #_rest = new Rest()
    _callback = null

    authenticate(email, password) {
        this.#_rest.sendQuery(
        'POST',
        '/login',
        {
            email: email,
            password: password
        },
        this._callback)
    }
    me() {
        this.#_rest.sendQuery(
        'GET',
        '/me',
        {},
        this._callback)
    }
    logout() {
        this.#_rest.sendQuery(
        'POST',
        '/logout',
        {},
        this._callback)
    }
    findMyPassword(email) {
        this.#_rest.sendQuery(
        'POST',
        '/findMyPassword',
        {
            email: email
        },
        this._callback)
    }

    static hasRules (expected) {
        let user = new User(JSON.parse(localStorage.getItem("user")));
        let establishment = new Establishment(JSON.parse(localStorage.getItem("establishment")));
        let rules = user.listEstablishments.filter(_ => _.id === establishment.id)[0].rules;

        for (let i in rules)
            if(expected.indexOf(rules[i].toLowerCase()) >= 0)
                return true

        return false
    }
}

export default AuthController
