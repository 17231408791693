import React from "react";

const LevelIcon = props => {
    const { fill } = props;

    return (
        <svg
            clipRule="evenodd"
            fillRule="evenodd"
            height="1em"
            imageRendering="optimizeQuality"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
            viewBox="0 0 1707 1707"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M390 1587H157c-22 0-40-18-40-40v-271H40c-15 0-29-8-36-22-6-14-5-30 4-42l234-311c7-10 19-16 32-16 12 0 24 6 32 16l230 308c7 7 11 17 11 27 0 22-18 40-40 40h-77v271c0 22-18 40-40 40z"
                fill="#df5a49"
            />
            <path
                d="M970 1587H736c-22 0-39-18-39-40v-388h-77c-15 0-29-9-36-22-7-14-5-30 4-42l233-311c8-10 20-16 32-16 13 0 25 6 32 16l231 308c7 7 11 16 11 27 0 22-18 40-40 40h-77v388c0 22-18 40-40 40z"
                fill="#5fd3ae"
            />
            <path
                d="M1550 1587h-234c-22 0-40-18-40-40v-505h-77c-15 0-29-9-35-22-7-14-6-30 3-42l234-311c7-10 19-16 32-16 12 0 24 6 32 16l231 308c6 7 11 16 11 27 0 22-18 40-40 40h-77v505c0 22-18 40-40 40z"
                fill="#2e9cf5"
            />
            <g fill="#fcbe37">
                <path
                    d="M152 856c-9 0-17-3-24-8-12-9-19-25-16-40l22-115-85-80c-11-11-16-27-11-42 5-14 18-25 33-27l117-15 49-106c7-14 21-23 37-23 15 0 29 9 36 23l50 106 116 15c15 2 28 13 33 27 5 15 0 31-11 42l-85 80 22 115c3 15-4 31-16 40-13 9-29 10-43 3l-102-57-103 57c-6 3-13 5-19 5zM731 725c-8 0-16-3-23-8-13-9-19-25-16-40l22-115-85-80c-12-11-16-27-11-42 5-14 18-25 33-27l116-14 50-107c7-14 21-23 36-23 16 0 30 9 36 23l50 107 116 14c16 2 29 13 34 27 4 15 0 31-11 42l-86 80 22 115c3 15-3 31-15 40-13 9-30 10-43 3l-103-57-102 57c-6 3-13 5-20 5zM1555 575c-7 0-13-2-19-5l-103-57-103 57c-13 7-30 6-43-3-12-9-18-25-15-40l22-115-86-80c-11-11-15-27-10-42 4-14 17-25 33-27l116-15 50-106c6-14 20-23 36-23 15 0 29 9 36 23l50 106 116 15c16 2 28 13 33 27 5 15 1 31-10 42l-86 80 22 115c3 15-3 31-16 40-7 5-15 8-23 8z"/>
            </g>
        </svg>
    );
}

export default LevelIcon;
