import React, { useEffect, useState } from "react"
import AddIcon from "../../../icons/AddIcon"
import InputSwitch from "./InputSwitch"
import DeleteIcon from "../../../icons/DeleteIcon"
import LoaderCircle from "../../../loader/LoaderCircle"
import AuthController from "../../../../stories/_auth/Auth/AuthController"
import LevelController from "../../../../stories/_school/Levels/LevelController";

const InputTag = props => {
    const { attribute, returnType, inputText, classname, values, list, dictionary, loading, titleButton, removeRules, handleChange } = props;
    const [ listInit, setListInit ] = useState(list);
    const [ listShowed, setListShowed ] = useState(false);
    const [ listDatas, setListDatas ] = useState([]);
    const [ internalLoading, setInternalLoading ] = useState(false);
    const [ levels, setLevels ] = useState([]);

    const buildListDatas = () => {
        let datas = [];

        for (let index in listInit) {
            datas.push({
                id: listInit[index].id !== undefined ? listInit[index].id : 0,
                label: buildDenomination(listInit[index]),
                value: buildIsIncluded(listInit[index]),
                master: listInit[index].master !== undefined ? listInit[index].master : false
            })
        }

        setListDatas(datas);
    }
    const atLeastOneMaster = () => {
        for (let i in listDatas)
            if (listDatas[i].master)
                return true

        return false
    }
    const masterProcess = () => {
        if (!atLeastOneMaster())
            return

        let datas = listDatas.slice()
        if (datas.length === 0) return

        let updated = false
        let countUnmasters = 0

        for (let i in datas)
            if (!datas[i].master && datas[i].value === 1)
                countUnmasters++

        if (countUnmasters === (datas.length - 1)) {
            for (let i in datas) {
                if (datas[i].master) {
                    updated = true
                    datas[i].value = 1
                }
                else {
                    updated = true
                    datas[i].value = 0
                }
            }
        }

        if (updated)
            setListDatas(datas)
    }
    const changeChoice = (attribute, returnType, val) => {
        let index
        let datas = listDatas.slice()
        if (datas.length === 0) return

        if (datas[0].id !== 0)
            index = datas.findIndex(_ => _.id === attribute)
        else
            index = datas.findIndex(_ => _.label === attribute)

        datas[index].value = val

        if (atLeastOneMaster()) {
            if (datas[index].master && datas[index].value === 1) {
                for (let i in datas) {
                    if (parseInt(i) !== index)
                        datas[i].value = 0
                }
            }
            else {
                for (let i in datas) {
                    if (datas[i].master)
                        datas[i].value = 0
                }
            }
        }

        setListDatas(datas)
    }
    const showList = () => {
        setListShowed(!listShowed);
    }
    const buildDenomination = data => {
        switch (data.type) {
            case "license": return data.object.name + " - " + data.object.number
            case "text": return (dictionary !== undefined && dictionary !== null) ? dictionary[data.value] : data.value
            case "rule": return (dictionary !== undefined && dictionary !== null) ? dictionary[data.label] : data.label
            default: return data.label
        }
    }
    const buildIsIncluded = data => {
        switch (data.type) {
            case "license": return values.includes(data.id) ? 1 : 0;
            case "text": return values.includes(data.value) ? 1 : 0;
            case "rule": return values.includes(data.label) ? 1 : 0;
            default: return values.includes(data.label) ? 1 : 0;
        }
    }
    const buildChoice = (choice, index) => {
        return <li key={ index }>
            <InputSwitch
                attribute={ choice.id !== 0 ? choice.id : choice.label }
                returnType="int"
                label={ choice.label }
                classname=""
                value={ choice.value }
                handleChange={ changeChoice } />
        </li>
    }
    const getObjectLabel = val => {
        if (listInit.length === 0) return

        let index

        if (listInit[0].id !== undefined)
            index = listInit.findIndex(_ => _.id === val)
        else
            index = listInit.findIndex(_ => _.label === val)

        if (index >= 0)
            return buildDenomination(listInit[index]);
        else
            return "";
    }
    const valuesToArray = () => {
        let returnValues = []
        let value

        for (let index in listDatas) {
            if (listDatas[index].value === 1) {
                if (listDatas[index].id !== 0)
                    value = listDatas[index].id
                else {
                    if (dictionary === undefined || dictionary === null)
                        value = listDatas[index].label
                    else {
                        for (let key in dictionary) {
                            if (listDatas[index].label === dictionary[key]) {
                                value = key
                                break
                            }
                        }
                    }
                }

                returnValues.push(value)
            }
        }

        return returnValues
    }
    const submitEvent = () => {
        showList()

        if (handleChange === null) return
        handleChange(attribute, returnType, valuesToArray())
    }
    const deleteEvent = val => {
        let returnValues = valuesToArray()
        if (returnValues.includes(val))
            returnValues.splice(returnValues.indexOf(val), 1)

        if (handleChange === null) return
        handleChange(attribute, returnType, returnValues)
    }
    const getLevels = () => {
        setInternalLoading(true);

        let controller = new LevelController();
        controller._callback = returnGetLevels;
        controller.index();
    }
    const returnGetLevels = (listObjects, error, status) => {
        setInternalLoading(false);

        switch (status) {
            case 200:
                let listTmp = [];

                for (let i in listObjects) {
                    listTmp.push({
                        label: listObjects[i].name,
                        value: 0,
                        type: "level"
                    });
                }

                setListInit(listTmp);

                break;
            case 500:
            default: break;
        }
    }

    useEffect(() => {
        if (attribute === "levels")
            getLevels();
    }, [attribute]);
    useEffect(() => {
        buildListDatas()
    }, [listInit, values])
    useEffect(() => {
        setTimeout(() => { masterProcess() }, 700)
    }, [listDatas])

    return (
        <div className={ "inputTag " + (classname !== undefined ? classname : "") }>
            <div className={ "containerTag " + (titleButton !== undefined ? "buttonWithTitle" : "buttonSvg") }>
                {
                    (values !== undefined && values.length > 0)
                    && values.map((val, index) => (
                        <div key={ index } className="tag">
                            <p>{ getObjectLabel(val) }</p>
                            <DeleteIcon handleClick={ () => { deleteEvent(val) } } />
                        </div>
                    ))
                }
                {
                    inputText
                    && <input type="text" placeholder="Ajouter" />
                }
            </div>
            {
                (loading === undefined && !loading && !internalLoading)
                && (
                    titleButton !== undefined
                    ? <p className="button" onClick={ showList }>{ titleButton }</p>
                    : <AddIcon handleClick={ showList } />
                )
            }
            <div className="clearing" />
            {
                ((loading !== undefined && loading) || internalLoading)
                && <LoaderCircle display="loader inputForm noMarginRight" strokeWidth="12" stroke="#00406F" />
            }
            {
                <div className={"OverBox overList " + (listShowed ? " appear" : "disappear")}>
                    <div className="form noPaddingT">
                        <ul className="listTag">
                            {
                                listDatas.length === 0
                                    ? <li className="empty">Aucun élément</li>
                                    : listDatas.map((choice, index) => buildChoice(choice, index))
                            }
                        </ul>
                        <div className={"containerButtons right"}>
                            <button className="submit right" onClick={submitEvent}>Valider</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default InputTag;
