import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router";
import LoaderCircle from "../../loader/LoaderCircle";
import AddIcon from "../../icons/AddIcon";
import DomainController from "../../../stories/_skill/Domains/DomainController";
import AbilityController from "../../../stories/_skill/Abilities/AbilityController";
import AuthController from "../../../stories/_auth/Auth/AuthController";
import Domain from "../../../stories/_skill/Domains/Domain";
import Ability from "../../../stories/_skill/Abilities/Ability";
import FormBuilder from "../../../class/tool/FormBuilder";
import SubAbilityController from "../../../stories/_skill/SubAbilities/SubAbilityController";
import SubAbility from "../../../stories/_skill/SubAbilities/SubAbility";
import EstablishmentController from "../../../stories/_school/Establishments/EstablishmentController";
import Establishment from "../../../stories/_school/Establishments/Establishment";
import AppContext from "../../../context/AppContext";
import "../../../css/OverBox.css";
import "../../../css/form/Form.css";
import User from "../../../stories/_account/Users/User";
import UserController from "../../../stories/_account/Users/UserController";
import StudentController from "../../../stories/_people/Students/StudentController";
import Student from "../../../stories/_people/Students/Student";
import ClassroomController from "../../../stories/_school/Classrooms/ClassroomController";
import Classroom from "../../../stories/_school/Classrooms/Classroom";

const OverBoxForm = props => {
    const { className, datas } = props;
    const { setError, setValid, setOverBoxForm, items, setItems, list, setList, search, setSearch } = useContext(AppContext);
    const [ item, setItem ] = useState(null);
    const [ disable, setDisable ] = useState(true);
    const [ values, setValues ] = useState({});
    const [ errors, setErrors ] = useState([]);
    const [ rows, setRows ] = useState([]);
    const [ updated, setUpdated ] = useState(false);
    const [ surToClose, setSurToClose ] = useState(false);
    const [ surToRemove, setSurToRemove ] = useState(false);
    const [ saving, setSaving ] = useState(false);
    const [ removing, setRemoving ] = useState(false);
    const urlParams = useParams();
    const establishment = new Establishment(JSON.parse(localStorage.getItem("establishment")));

    const extractParams = () => {
        if (datas === null)
            return;

        let pathExplode;

        switch (datas.type) {
            case "domain":
                return {};
            case "ability":
                pathExplode = urlParams['*'].split('/');

                return {
                    idDomain: parseInt(pathExplode[1])
                };
            case "subAbility":
                pathExplode = urlParams['*'].split('/');

                if (pathExplode.length === 2) {
                    return {
                        idDomain: parseInt(pathExplode[1])
                    };
                }
                else if (pathExplode.length > 2) {
                    return {
                        idDomain: parseInt(pathExplode[1]),
                        idAbility: parseInt(pathExplode[3])
                    };
                }
                else
                    return {};
            default:
                return {};
        }
    }
    const getController = () => {
        let controller;

        switch (datas.type) {
            case "domain":
                controller = new DomainController();
                break;
            case "ability":
                controller = new AbilityController();
                break;
            case "subAbility":
                controller = new SubAbilityController();
                break;
            case "user":
                controller = new UserController();
                break;
            case "establishment":
                controller = new EstablishmentController();
                break;
            case "student":
                controller = new StudentController();
                break;
            case "classroom":
                controller = new ClassroomController();
                break;
            default:
                controller = null;
                break;
        }

        return controller;
    }
    const init = () => {
        if (datas === null) {
            setItem(null);
            return;
        }

        switch (datas.action) {
            case "add":
                initNewObjectItem();
                break;
            case "update":
                setTimeout(() => { getObjectItem() }, 1000);
                break;
        }
    }
    const initNewObjectItem = () => {
        if (datas === null)
            return;

        switch (datas.type) {
            case "domain":
                setItem(new Domain());
                break;
            case "ability":
                setItem(new Ability());
                break;
            case "subAbility":
                setItem(new SubAbility());
                break;
            case "user":
                setItem(new User());
                break;
            case "establishment":
                setItem(new Establishment());
                break;
            case "student":
                setItem(new Student());
                break;
            case "classroom":
                setItem(new Classroom());
                break;
            default:
                setError("Type d'objet inconnu");
                setOverBoxForm(null);
                return;
        }
    }
    const getObjectItem = () => {
        let controller = getController();
        if (controller === null)
            return;

        controller._callback = returnGetObjectItem;

        switch (datas.type) {
            case "domain":
                controller.show(datas.object.id);
                break;
            case "ability":
                controller.show(datas.object.domain_id, datas.object.id);
                break;
            case "subAbility":
                const params = extractParams();
                controller.show(params.idDomain, datas.object.ability_id, datas.object.id);
                break;
            case "user":
                controller.show(datas.object.id);
                break;
            case "establishment":
                controller.show(datas.object.id);
                break;
            case "student":
                controller.show(datas.object.id);
                break;
            case "classroom":
                controller.show(datas.object.id);
                break;
            default:
                setError("Type d'objet inconnu");
                setOverBoxForm(null);
                return;
        }
    }
    const returnGetObjectItem = (obj, error, status) => {
        switch (status) {
            case 200:
                setItem(obj);
                break;
            default:
                setError("Impossible d'ouvrir le formulaire demandé");
                setItem(null);
                setOverBoxForm(null);
                break;
        }
    }
    const initForm = () => {
        if (item === null)
            return;

        let controller = getController();
        if (controller === null)
            return;

        controller.setFormValues(item, setValues);
        controller.setFormRows(setRows, datas.action === "add");
    }
    const buildTitle = () => {
        if (datas === null)
            return;

        switch (datas.type) {
            case "domain":
                return datas.action === "add" ? "Ajouter un domaine" : "Modifier le domaine";
            case "ability":
                return datas.action === "add" ? "Ajouter une compétence" : "Modifier la compétence";
            case "subAbility":
                return datas.action === "add" ? "Ajouter une sous-compétence" : "Modifier la sous-compétence";
            case "user":
                return datas.action === "add" ? "Ajouter un utilisateur" : "Modifier l'utilisateur";
            case "establishment":
                return datas.action === "add" ? "Ajouter un établissement" : "Modifier l'établissement";
            case "student":
                return datas.action === "add" ? "Ajouter un élève" : "Modifier l'élève";
            case "classroom":
                return datas.action === "add" ? "Ajouter une classe" : "Modifier la classe";
            default:
                return "";
        }
    }
    const change = (attribute, returnType, val, strict) => {
        FormBuilder.handleChange(rows, setValues, attribute, returnType, val, strict);
        setUpdated(true);
    }
    const close = () => {
        if(updated)
            setSurToClose(true);
        else
            confirmClose();
    }
    const confirmClose = () => {
        abortClose();
        setUpdated(false);
        setItem(null);
        setOverBoxForm(null);
    }
    const abortClose = () => {
        setSurToClose(false);
    }
    const checkDatas = () => {
        if (datas === null || values === null)
            return;

        const controller = getController();
        if (controller === null)
            return;

        if (controller.checkMinimumDatas(values, datas.action === "add" ? "POST" : "PUT"))
            setDisable(false);
        else
            setDisable(true);
    }
    const check422Errors = error => {
        setError("Certaines données sont incorrectes")

        if(error !== undefined) {
            let keys = Object.keys(error)
            setErrors(keys)
        }
    }
    const submit = event => {
        if (event !== undefined && event !== null)
            event.preventDefault()

        save()
    }
    const save = () => {
        if (saving || disable)
            return;

        const params = extractParams();

        setSaving(true);
        setError(null);
        setErrors([]);

        const controller = getController();

        let datasToSend;

        if (datas.action === "add") {
            controller._callback = returnSavePost;
            datasToSend = controller.returnPostDatas(item, values);
        }
        else {
            controller._callback = returnSavePut;
            datasToSend = controller.returnUpdatesFromCompare(item, values);
        }

        if(Object.keys(datasToSend).length === 0) {
            setSaving(false);
            return;
        }

        if (datas.action === "add") {
            switch (datas.type) {
                case "domain":
                case "user":
                case "establishment":
                case "student":
                    controller.post(datasToSend);
                    break;
                case "classroom":
                    controller.post(establishment.id, datasToSend);
                    break;
                case "ability":
                    controller.post(params.idDomain, datasToSend);
                    break;
                case "subAbility":
                    controller.post(params.idDomain, params.idAbility !== undefined ? params.idAbility : datas.ability_id, datasToSend);
                    break;
                default: break;
            }
        }
        else {
            switch (datas.type) {
                case "domain":
                case "user":
                case "establishment":
                case "student":
                    controller.put(item, datasToSend);
                    break;
                case "classroom":
                    controller.put(establishment.id, item, datasToSend);
                    break;
                case "ability":
                    controller.put(params.idDomain, item, datasToSend);
                    break;
                case "subAbility":
                    controller.put(params.idDomain, item.ability_id, item, datasToSend);
                    break;
                default: break;
            }
        }
    }
    const returnSavePost = (response, error, status) => {
        setSaving(false)

        switch (status) {
            case 201:
                setUpdated(false);

                if (['domain', 'ability', 'subAbility', 'etablissements'].includes(datas.type)) {
                    let itemsTmp = items.slice(0, items.length - 1);

                    switch (datas.type) {
                        case "domain":
                            let domain = new Domain(response.data);

                            itemsTmp.push({
                                id: domain.id,
                                title: domain.name,
                                link: "/domaines/" + domain.id,
                                object: domain,
                                edit: AuthController.hasRules(["admin", "manager"])
                            });

                            break;
                        case "ability":
                            let ability = new Ability(response.data);

                            itemsTmp.push({
                                id: ability.id,
                                title: ability.name,
                                link: "/domaines/" + ability.domain_id + "/competences/" + ability.id,
                                object: ability,
                                edit: AuthController.hasRules(["admin", "manager"])
                            });

                            break;
                        case "subAbility":
                            const params = extractParams();
                            let subAbility = new SubAbility(response.data);

                            itemsTmp.push({
                                id: subAbility.id,
                                title: subAbility.name,
                                link: "/domaines/" + params.idDomain + "/competences/" + subAbility.ability_id + "/sous-competences/" + subAbility.id,
                                object: subAbility,
                                edit: AuthController.hasRules(["admin", "manager"])
                            });

                            break;
                        case "establishment":
                            let establishment = new Establishment(response.data);

                            itemsTmp.push({
                                id: establishment.id,
                                title: establishment.name,
                                link: "/establishments/" + establishment.id,
                                object: establishment,
                                edit: AuthController.hasRules(["admin", "manager"])
                            });

                            break;
                        default: break;
                    }

                    // sorting
                    itemsTmp = itemsTmp.sort(sorting);

                    if (AuthController.hasRules(["admin", "manager"])) {
                        switch (datas.type) {
                            case "domain":
                                itemsTmp.push({
                                    icon: <AddIcon fill={"#FFFFFF"} />,
                                    id: null,
                                    title: "Ajouter un domaine",
                                    link: "/domaines/nouveau",
                                    object: null,
                                    add: true,
                                    type: "domain"
                                });

                                break;
                            case "ability":
                                let ability = new Ability(response.data);

                                itemsTmp.push({
                                    icon: <AddIcon fill={"#FFFFFF"} />,
                                    id: null,
                                    title: "Ajouter une compétence",
                                    link: "/domaines/" + ability.domain_id + "/competences/nouveau",
                                    object: null,
                                    add: true,
                                    type: "ability"
                                });

                                break;
                            case "subAbility":
                                const params = extractParams();
                                let subAbility = new SubAbility(response.data);

                                itemsTmp.push({
                                    icon: <AddIcon fill={"#FFFFFF"} />,
                                    id: null,
                                    title: "Ajouter une sous-compétence",
                                    link: "/domaines/" + params.idDomain + "/competences/" + subAbility.ability_id + "/sous-competences/nouveau",
                                    object: null,
                                    add: true,
                                    type: "subAbility"
                                });

                                break;
                            case "establishment":
                                itemsTmp.push({
                                    icon: <AddIcon fill={"#FFFFFF"} />,
                                    id: null,
                                    title: "Ajouter un établissement",
                                    link: "/establishments/nouveau",
                                    object: null,
                                    add: true,
                                    type: "establishments"
                                });

                                break;
                            default: break;
                        }
                    }

                    setItems(itemsTmp);
                }
                else if (['user', 'student', 'classroom'].includes(datas.type)) {
                    setSearch(search + 1);
                }

                setValid(validText("add"));
                confirmClose();

                break;
            case 422:
                check422Errors(error);
                break;
            default:
                setError("Une erreur s'est produite lors de la création");
                break;
        }
    }
    const returnSavePut = (response, error, status) => {
        setSaving(false)

        switch (status) {
            case 200:
                setUpdated(false);

                let entity = null;

                if (['domain', 'ability', 'subAbility', 'etablissements'].includes(datas.type)) {
                    let indexItem = items.findIndex(_ => _.id === item.id);

                    if (indexItem >= 0) {
                        let itemsTmp = items.slice();

                        switch (datas.type) {
                            case "domain":
                                entity = new Domain(response.data);
                                break;
                            case "ability":
                                entity = new Ability(response.data);
                                break;
                            case "subAbility":
                                entity = new SubAbility(response.data);
                                break;
                            case "establishment":
                                entity = new Establishment(response.data);
                                break;
                            default: break;
                        }

                        if (entity !== null) {
                            itemsTmp[indexItem].title = entity.name.toLocaleLowerCase();
                            itemsTmp[indexItem].object = entity;
                        }

                        if (['domain', 'ability', 'subAbility'].includes(datas.type) || ['etablissements'].includes(urlParams['*'])) {
                            // extract new add
                            let indexAdd = items.findIndex(_ => _.id === null);

                            if (indexAdd >= 0) {
                                itemsTmp = itemsTmp.slice(0, -1);
                            }

                            // sorting
                            itemsTmp = itemsTmp.sort(sorting);

                            if (AuthController.hasRules(["admin", "manager"])) {
                                switch (datas.type) {
                                    case "domain":
                                        itemsTmp.push({
                                            icon: <AddIcon fill={"#FFFFFF"} />,
                                            id: null,
                                            title: "Ajouter un domaine",
                                            link: "/domaines/nouveau",
                                            object: null,
                                            add: true,
                                            type: "domain"
                                        });

                                        break;
                                    case "ability":
                                        let ability = new Ability(response.data);

                                        itemsTmp.push({
                                            icon: <AddIcon fill={"#FFFFFF"} />,
                                            id: null,
                                            title: "Ajouter une compétence",
                                            link: "/domaines/" + ability.domain_id + "/competences/nouveau",
                                            object: null,
                                            add: true,
                                            type: "ability"
                                        });

                                        break;
                                    case "subAbility":
                                        const params = extractParams();
                                        let subAbility = new SubAbility(response.data);

                                        itemsTmp.push({
                                            icon: <AddIcon fill={"#FFFFFF"} />,
                                            id: null,
                                            title: "Ajouter une sous-compétence",
                                            link: "/domaines/" + params.idDomain + "/competences/" + subAbility.ability_id + "/sous-competences/nouveau",
                                            object: null,
                                            add: true,
                                            type: "subAbility"
                                        });

                                        break;
                                    case "establishment":
                                        let establishment = new Establishment(response.data);

                                        itemsTmp.push({
                                            icon: <AddIcon fill={"#FFFFFF"} />,
                                            id: null,
                                            title: "Ajouter un établissement",
                                            link: "/establishments/nouveau",
                                            object: null,
                                            add: true,
                                            type: "establishments"
                                        });

                                        break;
                                    default: break;
                                }
                            }
                        }

                        setItems(itemsTmp);
                    }
                }
                else if (['user', 'student', 'classroom'].includes(datas.type)) {
                    let indexItem = list.findIndex(_ => _.id === item.id);

                    if (indexItem >= 0) {
                        let listTmp = list.slice();

                        switch (datas.type) {
                            case "user":
                                entity = new User(response.data);
                                break;
                            case "student":
                                entity = new Student(response.data);
                                break;
                            case "classroom":
                                entity = new Classroom(response.data);
                                break;
                            default:
                                break;
                        }

                        listTmp[indexItem] = entity;
                        setList(listTmp);
                    }
                }

                setValid(validText("update"));
                confirmClose();

                break;
            case 422:
                check422Errors(error);
                break;
            default:
                setError("Une erreur s'est produite lors de l'enregistrement des données");
                break;
        }
    }
    const sorting = (o1, o2) => {
        const nameA = o1.title.toLocaleUpperCase()
        const nameB = o2.title.toLocaleUpperCase()
        const compare = nameA.localeCompare(nameB, undefined, {numeric: true, sensitivity: "base"})

        if (compare > 0)
            return 1
        else if (compare < 0)
            return -1
        else
            return 0
    }
    const buildRemoveLink = () => {
        if (datas === null)
            return;

        let ruleOk = false;

        if (['domain', 'ability', 'subAbility', 'user', 'student', 'classroom'].includes(datas.type) && AuthController.hasRules(["admin", "manager"])) {
            ruleOk = true;
        }
        else if (['establishment'].includes(datas.type) && AuthController.hasRules(["admin"])) {
            ruleOk = true;
        }

        if (ruleOk && item !== null && datas.action === "update") {
            let textLink = "Supprimer";

            switch (datas.type) {
                case "domain":
                    textLink = "Supprimer ce domaine";
                    break;
                case "ability":
                    textLink = "Supprimer cette compétence";
                    break;
                case "subAbility":
                    textLink = "Supprimer cette sous-compétence";
                    break;
                case "establishment":
                    textLink = "Supprimer cet établissement";
                    break;
                case "user":
                    textLink = "Supprimer cet utilisateur";
                    break;
                case "student":
                    textLink = "Supprimer cet élève";
                    break;
                default: break;
            }

            return <p className={"removeLink"} onClick={remove}>{ textLink }</p>
        }
        else
            return <></>;
    }
    const remove = () => {
        setSurToRemove(true);
    }
    const confirmRemove = () => {
        if (removing)
            return;

        setRemoving(true);

        const params = extractParams();
        const controller = getController();
        controller._callback = returnRemove;

        switch (datas.type) {
            case "domain":
            case "establishment":
            case "user":
            case "student":
                controller.delete(item);
                break;
            case "classroom":
                controller.delete(establishment.id, item);
                break;
            case "ability":
                controller.delete(params.idDomain, item);
                break;
            case "subAbility":
                controller.delete(params.idDomain, item.ability_id, item);
                break;
            default: break;
        }
    }
    const returnRemove = (response, error, status) => {
        setSaving(false)

        switch (status) {
            case 204:
                setRemoving(false);

                if (['domain', 'ability', 'subAbility', 'etablissements'].includes(datas.type)) {
                    let indexItem = items.findIndex(_ => _.id === item.id);

                    if (indexItem >= 0) {
                        let itemsTmp = items.slice();
                        itemsTmp.splice(indexItem, 1);
                        setItems(itemsTmp);
                    }
                }
                else if (['user', 'student', 'classroom'].includes(datas.type)) {
                    setSearch(search + 1);
                }

                setSurToRemove(false);
                setValid(validText("remove"));
                confirmClose();

                break;
            default:
                setError("Une erreur s'est produite lors de la suppression");
                break;
        }
    }
    const abortRemove = () => {
        setSurToRemove(false);
    }
    const validText = type => {
        let textValid = "";

        switch (datas.type) {
            case "domain":
                switch (type) {
                    case "add":
                        textValid = "Le domaine est ajouté";
                        break;
                    case "update":
                        textValid = "Le domaine est modifié";
                        break;
                    case "remove":
                        textValid = "Le domaine est supprimé";
                        break;
                    default: break;
                }

                break;
            case "ability":
                switch (type) {
                    case "add":
                        textValid = "La compétence est ajoutée";
                        break;
                    case "update":
                        textValid = "La compétence est modifiée";
                        break;
                    case "remove":
                        textValid = "La compétence est supprimée";
                        break;
                    default: break;
                }

                break;
            case "subAbility":
                switch (type) {
                    case "add":
                        textValid = "La sous-compétence est ajoutée";
                        break;
                    case "update":
                        textValid = "La sous-compétence est modifiée";
                        break;
                    case "remove":
                        textValid = "La sous-compétence est supprimée";
                        break;
                    default: break;
                }

                break;
            case "establishment":
                switch (type) {
                    case "add":
                        textValid = "L'établissement est ajouté";
                        break;
                    case "update":
                        textValid = "L'établissement est modifié";
                        break;
                    case "remove":
                        textValid = "L'établissement est supprimé";
                        break;
                    default: break;
                }

                break;
            case "user":
                switch (type) {
                    case "add":
                        textValid = "L'utilisateur est ajouté";
                        break;
                    case "update":
                        textValid = "L'utilisateur est modifié";
                        break;
                    case "remove":
                        textValid = "L'utilisateur est supprimé";
                        break;
                    default: break;
                }

                break;
            case "student":
                switch (type) {
                    case "add":
                        textValid = "L'élève est ajouté";
                        break;
                    case "update":
                        textValid = "L'élève est modifié";
                        break;
                    case "remove":
                        textValid = "L'élève est supprimé";
                        break;
                    default: break;
                }

                break;
            case "classroom":
                switch (type) {
                    case "add":
                        textValid = "La classe est ajoutée";
                        break;
                    case "update":
                        textValid = "La classe est modifiée";
                        break;
                    case "remove":
                        textValid = "La classe est supprimée";
                        break;
                    default: break;
                }

                break;
            default: break;
        }

        return textValid;
    }

    useEffect(() => {
        init();
    }, [datas]);
    useEffect(() => {
        initForm();
    }, [item]);
    useEffect(() => {
        checkDatas();
    }, [values]);

    return (
        <>
            <div className={"Overlay " + className}>
                <div className={"OverBox " + className}>
                    <p className={"title"}>{buildTitle()}</p>
                    <LoaderCircle classname={"loader" + (item === null ? " visible" : "")} stroke={"#ffffff"} strokeWidth={5} />
                    {
                        item !== null
                        && <div className={"form"}>{
                            rows.map((row, index) => (
                                <div key={index} className={"containerInput" + (row.classnameWrapper.length > 0 ? " " + row.classnameWrapper : "") + (Object.keys(errors).includes(row.attribute) ? " wrong" : "")}>
                                    <div className={"input withoutIcon"}>
                                        {
                                            row.noLabel === undefined
                                            && <label
                                                className={"label" + (row.classnameLabel !== undefined && row.classnameLabel.length > 0 ? " " + row.classnameLabel : "")}
                                                htmlFor={row.attribute}>
                                                {row.label}
                                            </label>
                                        }
                                        {
                                            FormBuilder.buildInputByType(row, values, errors, change, null, null, submit, null, null, null, index === 0)
                                        }
                                    </div>
                                </div>
                            ))
                        }</div>
                    }
                    {
                        buildRemoveLink()
                    }
                    <div className={"containerButtons"}>
                        <div className={"cancel"} onClick={close}><p>Fermer</p></div>
                        <div className={"submit" + (disable ? " disable" : "")} onClick={submit}>
                            {
                                saving
                                    ? <LoaderCircle classname="loader submitForm" strokeWidth="8" stroke="#FFFFFF"/>
                                    : <p>Enregistrer</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={"Overlay " + (surToClose ? " appear" : "disappear")}>
                <div className={"OverBox confirm " + (surToClose ? " appear" : "disappear")}>
                    <p className={"title"}>Attention</p>
                    <p className={"question"}>Des information sont en cours de saisie, es-tu certains de vouloir fermer cette fenêtre ?</p>
                    <div className={"containerButtons"}>
                        <div className={"cancel"} onClick={abortClose}><p>Non</p></div>
                        <div className={"submit"} onClick={confirmClose}><p>Oui</p></div>
                    </div>
                </div>
            </div>
            <div className={"Overlay " + (surToRemove ? " appear" : "disappear")}>
                <div className={"OverBox confirm " + (surToRemove ? " appear" : "disappear")}>
                    <p className={"title"}>Attention</p>
                    <p className={"question"}>Es-tu certains de vouloir supprimer définitivement cet élément ?</p>
                    <div className={"containerButtons"}>
                        <div className={"cancel"} onClick={abortRemove}><p>Non</p></div>
                        <div className={"submit remove" + (disable ? " disable" : "")} onClick={confirmRemove}>
                            {
                                removing
                                    ? <LoaderCircle classname="loader submitForm" strokeWidth="8" stroke="#FFFFFF"/>
                                    : <p>Oui</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OverBoxForm;