import React from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.bubble.css";

const InputRichText = props => {
    const { attribute, returnType, classname, value, placeholder, autoFocus, handleChange, handleBlur } = props;
    const theme = 'bubble';
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'link'],
        ],
    };
    const formats = ['bold', 'italic', 'underline', 'strike', 'link'];
    const { quill, quillRef } = useQuill({ theme, modules, formats, placeholder });

    const changeEvent = value => {
        if (handleChange === undefined || handleChange === null) return;
        handleChange(attribute, returnType, value);
    }
    const blurEvent = () => {
        if (handleBlur === undefined || handleBlur === null) return;
        handleBlur();
    }

    React.useEffect(() => {
        if (quill) {
            quill.clipboard.dangerouslyPasteHTML(value); // init value

            quill.on('text-change', (delta, oldDelta, source) => {
                handleChange(attribute, returnType, quill.root.innerHTML);
            });

            if (autoFocus) {
                quill.focus();
            }
            else {
                quill.blur();
            }
        }
    }, [quill]);

    return (
        <div className={"inputRichText " + (classname !== undefined ? classname : "")} onBlur={blurEvent}>
            <div className={"input"}>
                <div ref={quillRef}/>
            </div>
        </div>
    );
}

export default InputRichText;
