import React from "react";

const UsersIcon = () => {
    return (
        <svg height="512pt" viewBox="0 -40 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg" id="fi_1165674">
            <path
                d="m366.210938 206.464844c0 13.300781-8 24.082031-17.863282 24.082031-9.867187 0-15.597656-12.382813-15.597656-25.683594s5.730469-22.484375 15.597656-22.484375c9.863282 0 17.863282 10.78125 17.863282 24.085938zm0 0"
                fill="#e8916f"></path>
            <path
                d="m478.539062 206.464844c0 13.300781 8 24.082031 17.863282 24.082031 9.867187 0 15.597656-12.382813 15.597656-25.683594s-5.730469-22.484375-15.597656-22.484375c-9.867188 0-17.863282 10.78125-17.863282 24.085938zm0 0"
                fill="#d98868"></path>
            <path d="m404.15625 281.125h36.433594v58.382812h-36.433594zm0 0" fill="#d98868"></path>
            <path
                d="m356.792969 228.730469v-49.238281c0-34.15625 27.6875-61.84375 61.84375-61.84375h7.476562c34.152344 0 61.839844 27.6875 61.839844 61.84375v49.238281c0 36.191406-29.339844 65.53125-65.527344 65.53125h-.105469c-36.1875 0-65.527343-29.339844-65.527343-65.53125zm0 0"
                fill="#ebad88"></path>
            <path
                d="m426.066406 117.648438h-7.382812c-2.449219 0-4.863282.160156-7.238282.4375 30.765626 3.59375 54.648438 29.769531 54.648438 61.550781v48.941406c0 32.546875-23.632812 59.558594-54.648438 64.769531 3.554688.597656 7.203126.914063 10.929688.914063 36.21875 0 65.578125-29.410157 65.578125-65.683594v-48.945313c0-34.230468-27.707031-61.984374-61.886719-61.984374zm0 0"
                fill="#e8916f"></path>
            <path
                d="m507.628906 382.640625v8.332031c0 2.210938-1.792968 4-4 4h-162.507812c-2.210938 0-4-1.789062-4-4v-8.332031c0-35.863281 25.21875-64.9375 56.328125-64.9375h57.851562c31.109375 0 56.328125 29.074219 56.328125 64.9375zm0 0"
                fill="#47d5e6"></path>
            <path
                d="m451.300781 317.703125h-30.90625c31.109375 0 56.328125 29.074219 56.328125 64.9375v12.332031h26.90625c2.207032 0 4-1.789062 4-4v-8.332031c0-35.863281-25.21875-64.9375-56.328125-64.9375zm0 0"
                fill="#22c0d4"></path>
            <path d="m395.066406 317.703125h54.613282l-27.304688 77.269531zm0 0" fill="#dcdfe8"></path>
            <path
                d="m422.375 394.972656-19.320312-81.425781c-.410157-1.730469-2.226563-2.726563-3.902344-2.144531l-16.117188 5.589844c-1.628906.546874-2.726562 2.074218-2.726562 3.789062v28.363281c0 .765625.292968 1.5.816406 2.054688zm0 0"
                fill="#20b5c7"></path>
            <path
                d="m422.375 394.972656 19.320312-81.425781c.410157-1.730469 2.222657-2.726563 3.902344-2.144531l16.117188 5.589844c1.628906.546874 2.722656 2.074218 2.722656 3.789062v28.363281c0 .765625-.289062 1.5-.816406 2.054688zm0 0"
                fill="#20b5c7"></path>
            <path
                d="m356.511719 192.742188s5.457031 2.207031 11.058593-8.320313c6.40625-12.035156 23.285157-18.757813 43.515626-18.023437 23.347656.84375 33.898437-6.753907 39.273437-14.972657.460937-.707031 1.523437-.574219 1.792969.222657 6.84375 20.382812 16.132812 15.367187 22.238281 34.210937 3.617187 11.160156 13.097656 6.882813 13.097656 6.882813s26.09375-15.152344 12.648438-39.210938c2.308593-48.351562-35.546875-51.214844-35.546875-51.214844s-17.304688-22.007812-64.734375-10.558594c-68.441407 16.515626-56.289063 64.683594-56.289063 64.683594s-11.769531 22.816406 12.945313 36.300782zm0 0"
                fill="#ffd659"></path>
            <path
                d="m500.132812 153.53125c2.3125-48.347656-35.546874-51.210938-35.546874-51.210938s-15.011719-19.0625-55.355469-12.457031c8.667969 1.75 14.765625 4.847657 18.707031 7.515625 4.167969 2.824219 8.703125 5.09375 13.46875 6.726563 10.941406 3.753906 29.457031 15.179687 27.820312 49.425781 5.320313 9.515625 4.445313 17.628906 1.457032 23.972656 1.320312 2.171875 2.570312 4.847656 3.707031 8.355469 3.617187 11.160156 13.097656 6.882813 13.097656 6.882813s26.09375-15.152344 12.644531-39.210938zm0 0"
                fill="#ffbd54"></path>
            <path d="m71.410156 281.125h36.433594v58.382812h-36.433594zm0 0" fill="#d98868"></path>
            <path
                d="m24.046875 228.730469v-49.238281c0-34.15625 27.6875-61.84375 61.839844-61.84375h7.476562c34.15625 0 61.839844 27.6875 61.839844 61.84375v49.238281c0 36.191406-29.335937 65.53125-65.527344 65.53125h-.101562c-36.191407 0-65.527344-29.339844-65.527344-65.53125zm0 0"
                fill="#ebad88"></path>
            <path
                d="m93.316406 117.648438h-7.382812c-2.449219 0-4.863282.160156-7.238282.4375 30.765626 3.59375 54.648438 29.769531 54.648438 61.550781v48.941406c0 32.546875-23.632812 59.558594-54.648438 64.769531 3.554688.597656 7.203126.914063 10.929688.914063 36.21875 0 65.578125-29.410157 65.578125-65.683594v-48.945313c.003906-34.230468-27.707031-61.984374-61.886719-61.984374zm0 0"
                fill="#e8916f"></path>
            <path
                d="m174.878906 382.640625v8.332031c0 2.210938-1.789062 4-4 4h-162.507812c-2.207032 0-4-1.789062-4-4v-8.332031c0-35.863281 25.21875-64.9375 56.328125-64.9375h57.851562c31.109375 0 56.328125 29.074219 56.328125 64.9375zm0 0"
                fill="#ff2c5c"></path>
            <path
                d="m118.550781 317.703125h-30.90625c31.113281 0 56.328125 29.074219 56.328125 64.9375v12.332031h26.90625c2.210938 0 4-1.789062 4-4v-8.332031c0-35.863281-25.21875-64.9375-56.328125-64.9375zm0 0"
                fill="#e62753"></path>
            <path d="m62.320312 317.703125h54.613282l-27.308594 77.269531zm0 0" fill="#dcdfe8"></path>
            <path
                d="m89.625 394.972656-19.328125-81.457031c-.40625-1.71875-2.199219-2.714844-3.875-2.152344l-16.136719 5.425781c-1.628906.546876-2.722656 2.074219-2.722656 3.789063v28.566406c0 .761719.289062 1.5.816406 2.054688zm0 0"
                fill="#d6244d"></path>
            <path
                d="m89.625 394.972656 19.328125-81.457031c.40625-1.71875 2.203125-2.714844 3.875-2.152344l16.136719 5.425781c1.628906.546876 2.726562 2.074219 2.726562 3.789063v28.566406c0 .761719-.292968 1.5-.816406 2.054688zm0 0"
                fill="#d6244d"></path>
            <path
                d="m102.308594 149.605469s-38.28125 17.214843-51.5 39.445312c-17.371094 29.210938-17.835938 59.328125-26.421875 88.058594-.492188 1.644531-2.6875 1.957031-3.613281.507813-5.882813-9.203126-20.773438-35.15625-20.773438-62.132813 0-144.257813 105.464844-126.792969 110.175781-115.832031 21.269531-8.90625 63.925781 9.5 63.925781 94.003906 0 40.707031-4.59375 68.722656-16.996093 86.691406-.996094 1.441406-3.226563 1.019532-3.609375-.691406-2.390625-10.738281-8.628906-40.039062-11.605469-65.988281-6.816406-59.492188-39.582031-64.0625-39.582031-64.0625zm0 0"
                fill="#ffd659"></path>
            <path
                d="m110.175781 99.652344c-1.898437-4.414063-20.140625-9.882813-41.25-5.414063 3.734375.90625 6.558594 3.238281 10.015625 9.394531 4.609375 8.207032 11.496094 14.957032 20.039063 18.898438 22.972656 10.609375 37.457031 32.75 44.351562 71.125 1.628907 9.058594-.238281 17.753906-.742187 25.691406 3.160156 24.417969 8.691406 50.347656 10.90625 60.308594.382812 1.707031 2.613281 2.132812 3.609375.691406 12.402343-17.96875 16.996093-45.984375 16.996093-86.691406 0-84.503906-42.65625-102.910156-63.925781-94.003906zm0 0"
                fill="#ffbd54"></path>
            <path
                d="m176.59375 166.316406c0 18.746094-11.308594 33.941406-25.253906 33.941406-13.949219 0-22.050782-17.449218-22.050782-36.195312s8.101563-31.6875 22.050782-31.6875c13.945312 0 25.253906 15.195312 25.253906 33.941406zm0 0"
                fill="#e8916f"></path>
            <path
                d="m335.40625 166.316406c0 18.746094 11.308594 33.941406 25.253906 33.941406 13.949219 0 22.050782-17.449218 22.050782-36.195312s-8.101563-31.6875-22.050782-31.6875c-13.945312 0-25.253906 15.195312-25.253906 33.941406zm0 0"
                fill="#d98868"></path>
            <path d="m230.246094 271.542969h51.507812v82.285156h-51.507812zm0 0" fill="#d98868"></path>
            <path
                d="m163.285156 197.414062v-68.839843c0-48.289063 39.144532-87.429688 87.429688-87.429688h10.570312c48.289063 0 87.433594 39.140625 87.433594 87.429688v68.839843c0 51.164063-41.480469 92.644532-92.644531 92.644532h-.144531c-51.167969 0-92.644532-41.480469-92.644532-92.644532zm0 0"
                fill="#ebad88"></path>
            <path
                d="m261.21875 41.144531h-10.4375c-3.464844 0-6.875.222657-10.234375.613281 43.5 5.066407 77.265625 41.960938 77.265625 86.75v68.980469c0 45.867188-33.414062 83.9375-77.265625 91.285157 5.027344.839843 10.1875 1.285156 15.453125 1.285156 51.207031 0 92.714844-41.445313 92.714844-92.570313v-68.980469c0-48.25-39.171875-87.363281-87.496094-87.363281zm0 0"
                fill="#e8916f"></path>
            <path
                d="m376.53125 414.617188v11.382812c0 3.316406-2.6875 6-6 6h-229.0625c-3.3125 0-6-2.683594-6-6v-11.382812c0-50.542969 35.652344-91.515626 79.636719-91.515626h81.789062c43.984375 0 79.636719 40.972657 79.636719 91.515626zm0 0"
                fill="#685c79"></path>
            <path
                d="m296.894531 323.101562h-30.90625c43.984375 0 79.640625 40.972657 79.640625 91.515626v17.382812h24.902344c3.316406 0 6-2.683594 6-6v-11.382812c0-50.542969-35.652344-91.515626-79.636719-91.515626zm0 0"
                fill="#594f69"></path>
            <path d="m217.394531 323.101562h77.210938l-38.605469 108.898438zm0 0" fill="#ffd659"></path>
            <g fill="#d98868">
                <path
                    d="m300.570312 211.019531c-3.835937-1.5625-8.214843.285157-9.773437 4.121094-.070313.179687-7.558594 17.832031-33.871094 17.832031-26.179687 0-35.503906-17.472656-35.953125-18.347656-1.835937-3.6875-6.308594-5.203125-10.007812-3.394531-3.722656 1.820312-5.265625 6.308593-3.445313 10.03125.535157 1.089843 13.472657 26.710937 49.40625 26.710937 36.292969 0 47.316407-26.070312 47.765625-27.179687 1.558594-3.835938-.285156-8.210938-4.121094-9.773438zm0 0"></path>
                <path
                    d="m313.902344 151.726562c-.761719-.183593-18.894532-4.414062-40.328125 3.207032-3.902344 1.390625-5.941407 5.679687-4.554688 9.582031 1.09375 3.070313 3.984375 4.988281 7.066407 4.988281.835937 0 1.683593-.140625 2.515624-.4375 17.046876-6.0625 31.667969-2.785156 31.785157-2.757812 4.023437.972656 8.078125-1.503906 9.046875-5.53125.972656-4.027344-1.503906-8.078125-5.53125-9.050782zm0 0"></path>
                <path
                    d="m233.398438 169.066406c.832031.296875 1.679687.4375 2.511718.4375 3.085938 0 5.976563-1.917968 7.066406-4.988281 1.390626-3.902344-.648437-8.191406-4.550781-9.582031-21.433593-7.621094-39.566406-3.390625-40.332031-3.207032-4.011719.96875-6.472656 4.996094-5.523438 9.015626.953126 4.019531 4.992188 6.503906 9.011719 5.574218.148438-.035156 14.769531-3.3125 31.816407 2.75zm0 0"></path>
            </g>
            <path
                d="m256 432-27.640625-116.121094c-.40625-1.714844-2.199219-2.707031-3.871094-2.148437l-25.230469 8.457031c-1.628906.542969-2.730468 2.070312-2.730468 3.792969v41.578125c0 .960937.460937 1.867187 1.242187 2.429687l12.589844 9.113281c.777344.566407 1.238281 1.46875 1.238281 2.429688v16.71875c0 .960938.460938 1.863281 1.238282 2.425781zm0 0"
                fill="#50475e"></path>
            <path
                d="m256 432 27.640625-116.121094c.40625-1.714844 2.199219-2.707031 3.871094-2.148437l25.230469 8.457031c1.628906.542969 2.730468 2.070312 2.730468 3.792969v41.578125c0 .960937-.460937 1.867187-1.242187 2.429687l-12.589844 9.113281c-.777344.566407-1.238281 1.46875-1.238281 2.429688v16.71875c0 .960938-.460938 1.863281-1.238282 2.425781zm0 0"
                fill="#50475e"></path>
            <path
                d="m162.886719 146.980469s7.710937 3.105469 15.632812-11.726563c9.054688-16.964844 32.921875-26.4375 61.519531-25.40625 33.703126 1.214844 48.542969-9.96875 55.992188-21.832031.453125-.714844 1.527344-.582031 1.792969.222656 9.703125 29.6875 23.003906 22.226563 31.71875 49.039063 5.109375 15.730468 18.515625 9.703125 18.515625 9.703125s36.890625-21.355469 17.878906-55.265625c3.265625-68.144532-50.257812-72.179688-50.257812-72.179688s-24.460938-31.015625-91.519532-14.882812c-96.761718 23.28125-79.582031 91.167968-79.582031 91.167968s-16.640625 32.15625 18.308594 51.160157zm0 0"
                fill="#8c4a37"></path>
            <path
                d="m365.9375 91.714844c3.265625-68.144532-50.257812-72.179688-50.257812-72.179688s-19.472657-24.6875-71.292969-18.554687c28.875 3.960937 40.386719 18.554687 40.386719 18.554687s53.523437 4.035156 50.257812 72.179688c11.207031 19.992187 2.984375 35.617187-5.652344 45.078125.054688.164062.109375.320312.160156.484375 5.113282 15.730468 18.519532 9.703125 18.519532 9.703125s36.890625-21.355469 17.878906-55.265625zm0 0"
                fill="#804231"></path>
        </svg>
    );
}

export default UsersIcon;
