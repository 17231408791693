import Rest from "../../../class/tool/Rest";
import Classroom from "./Classroom";
import moment from "moment";
import Establishment from "../Establishments/Establishment";

class ClassroomController {
    #_rest = new Rest();
    _callback = null;
    _baseURL = "/establishments/{idEstablishment}/classrooms";
    _establishment = new Establishment(JSON.parse(localStorage.getItem("establishment")));

    checkName = value => {
        if (value !== null && value.length > 0)
            return true;

        return false;
    }
    checkYear = value => {
        if (value !== null && value.length > 0)
            return true;

        return false;
    }
    checkMinimumDatas(values, type) {
        return (
            this.checkName(values.name)
            && this.checkYear(values.year)
        );
    }

    setFormValues(object, setValues) {
        setValues(prev => ({
            ...prev,
            name: object.name !== null ? object.name.toLocaleUpperCase() : "",
            year: object.year
        }))
    }
    setFormRows(setRows, formNew = false) {
        let rows;

        if (formNew) {
            rows = [
                {
                    label: "Nom",
                    attribute: "name",
                    inputType: "text",
                    returnType: "string",
                    idWrapper: "nameInput",
                    classnameWrapper: "flex paddingBottom",
                    classnameLabel: "",
                    classnameInput: "",
                    placeholder: "Domaine"
                },
                {
                    label: "Année",
                    attribute: "year",
                    inputType: "select",
                    returnType: "string",
                    list: [
                        {
                            value: moment().format("YYYY") + " - " + moment().add(1, "year").format("YYYY")
                        },
                        {
                            value: moment().add(1, "year").format("YYYY") + " - " + moment().add(2, "year").format("YYYY")
                        }
                    ],
                    dictionary: null,
                    idWrapper: "yearInput",
                    classnameWrapper: "flex paddingBottom",
                    classnameLabel: "marginBottom",
                    classnameInput: "",
                    emptyText: "Aucun"
                },
                {
                    label: "Niveaux",
                    attribute: "levels",
                    returnType: "array",
                    inputType: "tag",
                    inputText: false,
                    titleButton: "Ajouter",
                    list: [],
                    dictionary: null,
                    idWrapper: "levelsInput",
                    classnameWrapper: "flex paddingBottom",
                    classnameLabel: "marginBottom",
                    classnameInput: "",
                    placeholder: ""
                }
            ]
        }
        else {
            rows = [
                {
                    label: "Nom",
                    attribute: "name",
                    inputType: "text",
                    returnType: "string",
                    idWrapper: "nameInput",
                    classnameWrapper: "flex paddingBottom",
                    classnameLabel: "",
                    classnameInput: "",
                    placeholder: "Domaine"
                },
                {
                    label: "Année",
                    attribute: "year",
                    inputType: "select",
                    returnType: "string",
                    list: [
                        {
                            value: moment().format("YYYY") + " - " + moment().add(1, "year").format("YYYY")
                        },
                        {
                            value: moment().add(1, "year").format("YYYY") + " - " + moment().add(2, "year").format("YYYY")
                        }
                    ],
                    dictionary: null,
                    idWrapper: "yearInput",
                    classnameWrapper: "flex paddingBottom",
                    classnameLabel: "marginBottom",
                    classnameInput: "",
                    emptyText: "Aucun"
                },
                {
                    label: "Niveaux",
                    attribute: "levels",
                    returnType: "array",
                    inputType: "tag",
                    inputText: false,
                    titleButton: "Ajouter",
                    list: [],
                    dictionary: null,
                    idWrapper: "levelsInput",
                    classnameWrapper: "flex paddingBottom",
                    classnameLabel: "marginBottom",
                    classnameInput: "",
                    placeholder: ""
                }
            ]
        }

        setRows(rows)
    }
    returnPostDatas(object, datas) {
        let datasToPost = {}
        let needed = ['name', 'year']
        let optionals = []

        for (let i in needed) {
            datasToPost[needed[i]] = datas[needed[i]]
        }

        for (let i in optionals) {
            if (datas[optionals[i]] === null) continue

            switch (typeof datas[optionals[i]]) {
                case "string":
                    if (datas[optionals[i]].length === 0) continue
                    break
                default: break
            }

            datasToPost[optionals[i]] = datas[optionals[i]]
        }

        return datasToPost
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        let dataKeys = Object.keys(datas);
        let key = "";
        let updates = {};

        for(let i in dataKeys) {
            key = dataKeys[i];

            if (datas[key] !== undefined) {
                if (compare) {
                    if (Reflect.get(object, key) !== datas[key] && datas[key] !== null && (datas[key].length > 0 || datas[key] >= 0))
                        Reflect.set(updates, key, datas[key]);
                }
                else {
                    if (datas[key] !== null)
                        Reflect.set(updates, key, datas[key]);
                }
            }
        }

        return updates;
    }

    index(input = null, page = null, numbers = null, sortingName = "lastname", sortingValue = "asc", tree = false) {
        let params = "?page=" + (page !== null ? page : 1) + "&nb=" + (numbers !== null ? numbers : 25);

        if (input !== null && input.length > 0) params += "&input=" + input
        if (sortingName !== null && sortingValue !== null) params += "&sorton=" + sortingName + "&sortvalue=" + sortingValue
        if (tree) params += "&tree="

        this.#_rest.sendQuery(
            'GET',
            this._baseURL.replace("{idEstablishment}", this._establishment.id) + params,
            {},
            this.handleIndex);
    }
    handleIndex = (response, error, status = 200) => {
        let listObjects = [];
        let pagination = null;

        if(status === 200) {
            let data;

            if (response.data !== undefined && response.data.data !== undefined) {
                if(response.data.total !== undefined) {
                    pagination = {
                        title: response.data.total > 1 ? " classes" : " classe",
                        total: response.data.total,
                        from: response.data.from != null ? response.data.from : 0,
                        to: response.data.to != null ? response.data.to : 0,
                        per_page: response.data.per_page,
                        current_page: response.data.current_page,
                        first_page_url: new URL(response.data.first_page_url).search,
                        last_page_url: new URL(response.data.last_page_url).search,
                        next_page_url: response.data.next_page_url !== null ? new URL(response.data.next_page_url).search : null,
                        prev_page_url: response.data.prev_page_url !== null ? new URL(response.data.prev_page_url).search : null
                    }
                }

                data = response.data.data;
            }
            else if (response.data)
                data = response.data;

            for(let item in data)
                listObjects.push(new Classroom(data[item]));
        }

        this._callback(listObjects, error, pagination, status);
    }

    show(id) {
        this.#_rest.sendQuery(
            'GET',
            this._baseURL.replace("{idEstablishment}", this._establishment.id) + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error, status = 200) => {
        const object = !error ? new Classroom(response.data) : null;
        this._callback(object, error, status);
    }

    post(datas) {
        this.#_rest.sendQuery(
            'POST',
            this._baseURL.replace("{idEstablishment}", this._establishment.id),
            datas,
            this.handlePost);
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status);
    }

    put(object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            this._baseURL.replace("{idEstablishment}", this._establishment.id) + '/' + object.id,
            datas,
            this.handlePut);
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status);
    }

    delete(object) {
        this.#_rest.sendQuery(
            'DELETE',
            this._baseURL.replace("{idEstablishment}", this._establishment.id) + '/' + object.id,
            {},
            this.handleDelete);
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status);
    }
}

export default ClassroomController;
