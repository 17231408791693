import React, {useEffect, useState} from "react"
import Switch from "react-switch"

const InputSwitch = props => {
    const { attribute, returnType, label, marginTop, marginBottom, classname, value, readonly, handleChange } = props
    const [ readOnly, setReadOnly ] = useState((readonly !== undefined && readonly !== null) ? readonly : false)

    const changeEvent = checked => {
        if (handleChange === null || handleChange === undefined) return
        handleChange(attribute, returnType, checked ? 1 : 0)
    }

    return (
        <div className={ "inputSwitch" + (classname !== undefined ? " " + classname : "") + (marginTop !== undefined ? " marginTop" : "") + (marginBottom !== undefined ? " marginBottom" : "" + (value === 1 ? " checked" : "")) }>
            <label>
                <span className={value === 1 ? "checked" : ""}>{label}</span>
                <Switch
                    checked={value === 1}
                    onChange={changeEvent}
                    onColor="#0065AC"
                    onHandleColor="#00406F"
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    disabled={readOnly}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={12}
                    width={30}
                    className="react-switch"
                    id="material-switch"/>
            </label>
        </div>)
}

export default InputSwitch
