import React, {useState} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./component/page/auth/Login";
import FindMyPassword from "./component/page/auth/FindMyPassword";
import FormPwd from "./component/form/account/FormPwd";
import Site from "./component/page/Site";
import AuthController from "./stories/_auth/Auth/AuthController";
import User from "./stories/_account/Users/User";
import "./css/Theme.css";
import SessionContext from "./context/SessionContext";

const Main = () => {
    const [ showDefinePwd, setShowDefinePwd ] = useState(false);

    const removeSession = () => {
        localStorage.clear();
        sessionStorage.clear();

        window.location.href = process.env.REACT_APP_URL;
    }
    const logout = () => {
        const auth = new AuthController()
        auth._callback = handleLogout
        auth.logout()
    }
    const handleLogout = (response, error, status) => {
        removeSession()
    }
    const me = () => {
        const auth = new AuthController()
        auth._callback = handleMe
        auth.me()
    }
    const handleMe = (response, error, status) => {
        switch (status) {
            case 200:
                let user = new User(response.data);

                localStorage.setItem("user", JSON.stringify(response.data));

                if (user.listEstablishments.length > 1)
                    window.location = "/choix-etablissement";
                else {
                    localStorage.setItem("establishment", JSON.stringify(user.listEstablishments[0]));
                    window.location = "/";
                }

                break;
            case 401:
            default:
                handleLogout();
                break;
        }
    }
    const checkDefinePwd = () => {
        if (localStorage.getItem("user") === null) return
        let user = JSON.parse(localStorage.getItem("user"))

        if (user.definePwd === 1)
            setShowDefinePwd(true)
    }
    const closeDefinePwd = () => {
        setShowDefinePwd(false)
    }
    const redirectProcess = () => {
        if (localStorage.getItem("logged") !== null)
            removeSession();

        let pathname = window.location.pathname.replace("/", "");
        let emptyPath = [""];
        let loginPath = ["login"];

        // LOGIN PROCESS
        if (!loginPath.includes(pathname) && localStorage.getItem("token") === null) {
            window.location = "/login";
            return;
        }

        // REFRESH OR UNLOG PROCESS
        const expiresIn = parseInt(localStorage.getItem("expires_in"));
        const rememberMe = localStorage.getItem("remember_me") === "true";

        if (expiresIn < (Date.now() / 1000) && rememberMe) {
            if (rememberMe)
                me();
            else
                removeSession();
        }

        // TARGET PROCESS IF NEEDED
        if (emptyPath.includes(pathname)) {
            redirectAfterLogin();
        }
    }
    const redirectAfterLogin = () => {
        window.location = "/home";
    }

    const valueSessionContext = {
        handleLogout: logout,
        handleCheckDefinePwd: checkDefinePwd,
        me
    }

    return (
        <SessionContext.Provider value={valueSessionContext}>
            <BrowserRouter>
                {
                    redirectProcess()
                }
                <Routes>
                    <Route exact path="/forgot" element={<FindMyPassword />} />
                    <Route exact path="/" element={<Login />} />
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/choix-etablissement" element={<Login />} />
                    <Route exact path="/*" element={<Site />} />
                </Routes>
                {
                    showDefinePwd
                    && <FormPwd object={ new User(JSON.parse(localStorage.getItem("user"))) } closeView={ closeDefinePwd } />
                }
            </BrowserRouter>
        </SessionContext.Provider>
    )
}

export default Main;
