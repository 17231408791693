import React, {useContext, useState} from "react";
import User from "../../../stories/_account/Users/User";
import LogoutIcon from "../../icons/LogoutIcon";
import SessionContext from "../../../context/SessionContext";

const UserPin = () => {
    const {handleLogout} = useContext(SessionContext);
    const [options, setOptions] = useState(false);
    const user = new User(JSON.parse(localStorage.getItem("user")));

    const defineHello = () => {
        let date = new Date();
        let hour = date.getHours();

        if (hour < 18)
            return "Bonjour"
        else
            return "Bonsoir"
    }
    const userOptions = () => {
        setOptions(!options);
    }

    return (
        <>
            <div className={"user"} onClick={userOptions}>
                <span className={"hello"}>{defineHello()},</span>
                <span className={"name"}>{user.fullnameLimited}</span>
            </div>
            <ul className={"userOptions" + (options ? " appear" : "")}>
                <li className={"item red"} onClick={handleLogout}>
                    <LogoutIcon className={"icon"} />
                    <p className={"text"}>Se déconnecter</p>
                </li>
            </ul>
        </>
    )
}

export default UserPin;