import React, {useEffect} from "react";
import FormFindMyPassword from "../../form/login/FormFindMyPassword";
import CalopinIcon from "../../icons/CalopinIcon";

const FindMyPassword = () => {
    useEffect(() => {
        document.title = "Calopin - Retrouver votre mot de passe";
    }, []);

    return (
        <div className={"box"}>
            <CalopinIcon classname={"logo"} />
            <h1>Retrouver votre mot de passe.</h1>
            <p>Un oubli, ça arrive à tout le monde ! Merci de saisir votre adresse email afin que l'on vous renvoit un mot de passe.</p>
            <FormFindMyPassword />
        </div>
    )
}

export default FindMyPassword;
