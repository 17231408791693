import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import AuthController from "../../../stories/_auth/Auth/AuthController";
import LoaderCircle from "../../loader/LoaderCircle";
import FormBuilder from "../../../class/tool/FormBuilder";
import SessionContext from "../../../context/SessionContext";
import '../../../css/form/Form.css';

const FormLogin = () => {
    const controller = new AuthController();

    const { handleLogout, me } = useContext(SessionContext);
    const [ values, setValues ] = useState({
        email: "",
        password: "",
        rememberMe: false
    })
    const [ errors, setErrors ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ readyToAuthenticate, setReadyToAuthenticate ] = useState(false)
    const [ globalError, setGlobalError ] = useState(null)
    const [ rows, setRows ] = useState([
        {
            label: "Email",
            attribute: "email",
            inputType: "text",
            returnType: "string",
            icon: "mail",
            idWrapper: "emailInput",
            classnameWrapper: "flex",
            classnameLabel: "",
            classnameInput: "",
            placeholder: "Email",
            emptyText: "Aucune"
        },
        {
            label: "Mot de passe",
            attribute: "password",
            inputType: "password",
            returnType: "string",
            icon: "password",
            idWrapper: "pwdInput",
            classnameWrapper: "flex",
            classnameLabel: "",
            classnameInput: "",
            placeholder: "Mot de passe",
            emptyText: ""
        }
    ])

    const handleRememberMe = () => {
        handleChange("rememberMe", "bool", !values.rememberMe);
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        FormBuilder.handleChange(rows, setValues, attribute, returnType, val, strict);
    }
    const call = () => {
        if (!readyToAuthenticate)
            return;

        reinitAllEdits();
        authenticate();
    }
    const reinitAllEdits = () => {
        setErrors([]);
    }
    const authenticate = () => {
        setLoading(true);

        controller._callback = handleReturnAuthenticate;
        controller.authenticate(values.email, values.password);
    }
    const handleReturnAuthenticate = (response, error, status) => {
        switch (status) {
            case 200:
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("expires_in", addSeconds(Date.now(), response.data.expires_in).toString());
                localStorage.setItem("remember_me", values.rememberMe ? "true" : "false");
                localStorage.setItem("user", JSON.stringify({}));
                localStorage.setItem("establishment", JSON.stringify({}));

                me();

                break;
            case 401:
                setGlobalError("Les identifiants sont incorrects");
                setLoading(false);
                break;
            default:
                setGlobalError("Une erreur s'est produite lors de l'authentification");
                setLoading(false);
                break;
        }
    }
    const handleSubmit = event => {
        if (!readyToAuthenticate)
            return;

        if (event !== undefined)
            event.preventDefault();

        call();
    }
    const addSeconds = (date, seconds) => {
        return Math.floor(date / 1000) + seconds;
    }
    const controlInputs = () => {
        let emailRegex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm;
        setReadyToAuthenticate((emailRegex.test(values.email) && values.password.length >= 5));
    }

    useEffect(() => {
        controlInputs()
    }, [values]);

    return (
        <form className="form">
            {
                globalError !== null
                && <p className="errorForm">{globalError}</p>
            }
            <div className={"wrapper"}>
                {
                    rows.map((row, index) => (
                        <div key={index}
                             id={row.idWrapper}
                             className={"containerInput" + (row.classnameWrapper !== undefined && row.classnameWrapper.length > 0 ? " " + row.classnameWrapper : "")}>
                            {
                                (row.icon !== undefined && row.icon.length > 0)
                                && <div className={"icon"}>
                                    {
                                        FormBuilder.buildIcon(row.icon)
                                    }
                                </div>
                            }
                            <div className={"input"}>
                                <label className={"label" + (row.classnameLabel !== undefined && row.classnameLabel.length > 0 ? " " + row.classnameLabel : "")}>{row.label}</label>
                                {
                                    FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className={"belowLogin"}>
                <label className="labelCheckbox">
                    <input type="checkbox" id="rememberMe" checked={values.rememberMe} value={values.rememberMe}
                           onChange={handleRememberMe}/>
                    <span className={"checkmark"} />
                    <p>Se souvenir de moi</p>
                </label>
                <Link className="forgot" to="/forgot">Mot de passe oublié ?</Link>
            </div>
            <div className={"submit right" + (!readyToAuthenticate ? " disable" : "")} onClick={handleSubmit}>
                {
                    loading
                        ? <LoaderCircle classname="loader submitFormLogin" strokeWidth="8" stroke="#FFFFFF" />
                        : "Connexion"
                }
            </div>
        </form>
    )
}

export default FormLogin;
