import React from "react"
import moment from "moment"
import InputText from "../../component/form/input/generated/InputText"
import NoInput from "../../component/form/input/generated/NoInput"
import MailIcon from "../../component/icons/MailIcon"
import PwdIcon from "../../component/icons/PwdIcon"
import InputRichText from "../../component/form/input/generated/InputRichText";
import InputTag from "../../component/form/input/generated/InputTag";
import InputSelect from "../../component/form/input/generated/InputSelect";

class FormBuilder {
    static buildInputByType(row, values, errors, handleChange = null, handleBlur = null, handleRecovery = null, handleSubmit = null, handleAdd = null, handleRemove = null, handleBold = null, firstFocus = null, key = null, handleFocus = null) {
        let inputType = ""
        //let params = []

        if (row.inputType.includes(".")) {
            let explode = row.inputType.split('.')
            inputType = explode[0]

            // for (let i = 1; i < explode.length; i++)
            //     params[i-1] = explode[i]
        }
        else
            inputType = row.inputType

        switch (inputType) {
            case "text":
            case "password":
                return <InputText
                    key={ key !== undefined ? key : 0 }
                    type={ row.inputType }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    variablePrice={ row.variablePrice !== undefined ? row.variablePrice : null }
                    classname={ row.classnameInput }
                    classError={ Object.keys(errors).includes(row.attribute) ? "wrong" : "" }
                    value={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    placeholder={ row.placeholder }
                    readonly={ row.readOnly !== undefined ? row.readOnly : null }
                    autoComplete={ row.autoComplete !== undefined ? row.autoComplete : null }
                    autoFocus={ firstFocus !== null ? firstFocus : null }
                    handleChange={ handleChange !== null ? handleChange : () => {} }
                    handleBlur={ handleBlur !== null ? handleBlur : () => {} }
                    handleSubmit={ handleSubmit !== null ? handleSubmit : null }
                    handleFocus={ handleFocus !== null ? handleFocus : null } />
            case "richText":
                return <InputRichText
                    key={ key !== undefined ? key : 0 }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    value={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    placeholder={ row.placeholder }
                    autoFocus={ firstFocus !== null ? firstFocus : null }
                    readonly={ row.readOnly !== undefined ? row.readOnly : null }
                    handleChange={ handleChange !== null ? handleChange : () => {} }
                    handleBlur={ handleBlur !== null ? handleBlur : () => {} } />
            case "select":
                return <InputSelect
                    key={ key !== undefined ? key : 0 }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    classname={ row.classnameInput }
                    classError={ Object.keys(errors).includes(row.attribute) ? "wrong" : "" }
                    value={ row.returnType === "int" ? FormBuilder.getIndexForValue(row, values) : values[row.attribute] }
                    list={ row.list }
                    dictionary={ row.dictionary }
                    readonly={ row.readOnly !== undefined ? row.readOnly : false }
                    autoFocus={ firstFocus !== null ? firstFocus : null }
                    placeholder={ row.placeholder }
                    loading={ row.loading }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                    handleBlur={ handleBlur != null ? handleBlur : () => {} }
                />
            case "tag":
                return <InputTag
                    key={ key !== undefined ? key : 0 }
                    attribute={ row.attribute }
                    returnType={ row.returnType }
                    inputText={ row.inputText }
                    list={ row.list }
                    dictionary={ row.dictionary }
                    titleButton={ row.titleButton }
                    classname={ row.classname }
                    values={ values[row.attribute] !== undefined ? values[row.attribute] : "" }
                    placeholder={ row.placeholder }
                    loading={ row.loading }
                    removeRules={ row.removeRules }
                    handleChange={ handleChange != null ? handleChange : () => {} }
                />
            default: break
        }
    }
    static getValueLineForDisplayText(initialAttribute, attribute, values) {
        let lines = typeof values[initialAttribute] === "object" ? values[initialAttribute] : JSON.parse(values[initialAttribute])
        let indexLine = parseInt(attribute.replace("line", "")) - 1

        return lines[indexLine] !== undefined ? lines[indexLine] : ""
    }
    static getValueLineForHeaderText(initialAttribute, attribute, values) {
        let lines = typeof values[initialAttribute] === "object" ? values[initialAttribute] : JSON.parse(values[initialAttribute])
        let indexLine = parseInt(attribute.replace(initialAttribute === "receiptHeader" ? "header" : "footer", ""))

        return lines[indexLine] !== undefined ? lines[indexLine].text : ""
    }
    static getBoldLineForHeaderText(initialAttribute, attribute, values) {
        let lines = typeof values[initialAttribute] === "object" ? values[initialAttribute] : JSON.parse(values[initialAttribute])
        let indexLine = parseInt(attribute.replace(initialAttribute === "receiptHeader" ? "header" : "footer", ""))

        return lines[indexLine] !== undefined ? lines[indexLine].bold : 0
    }
    static getIndexForValue(row, values) {
        let value = 0

        for (let i = 0; i < row.list.length; i++) {
            if (row.list[i].type !== undefined && row.list[i].type !== "") {
                if (
                    row.list[i].id === values[row.attribute]
                    && row.list[i].type === values[row.attribute.replace("_id", "_type")]
                ) {
                    value = i
                    break
                }
            }
            else {
                if (row.list[i].id === values[row.attribute]) {
                    value = i
                    break
                }
            }
        }

        return value
    }
    static buildNoInputByType(row, values, handleEdit) {
        const val = FormBuilder.getValueForNoInput(row, values)

        return <NoInput
            inputType={ row.inputType }
            attribute={ row.attribute }
            classname={ row.classnameNoInput }
            value={ val !== null && val !== "" ? val : row.emptyText }
            edit={ row.edit !== undefined ? row.edit : true }
            handleEdit={ handleEdit != null ? handleEdit : () => {} } />
    }
    static getValueForNoInput = (row, values) => {
        let value = ""

        switch (row.inputType) {
            case "select":
                if (row.returnType === "int")
                    value = FormBuilder.getDataByKeyInList(row, values, "value")
                else {
                    if (row.dictionary !== null)
                        value = row.dictionary[values[row.attribute]]
                    else
                        value = values[row.attribute]
                }
                break
            case "textarea":
                value = values[row.attribute]
                break
            default:
                value = values[row.attribute]
                break
        }

        return value
    }
    static getDataByKeyInList = (row, values, data) => {
        let filtered

        if (row.list === undefined) return ""

        if (row.attribute.includes("_id") && values[row.attribute.replace("_id", "_type")] !== undefined)
            filtered = row.list.filter(item => item.id === values[row.attribute] && item.type === values[row.attribute.replace("_id", "_type")])
        else
            filtered = row.list.filter(item => item.id === values[row.attribute])

        if (filtered.length === 0 || filtered[0][data] === undefined) return ""

        return filtered[0][data]
    }
    static buildVal = (returnType, val) => {
        switch (returnType) {
            case "string": return val
            case "int":
                switch (typeof val) {
                    case "string": return parseInt(val)
                    case "object":
                    case "undefined": return 0
                    default: return val
                }
            case "float": return val.replace(',', '.')
            case "date":
                return moment(val).format("DD/MM/YYYY")
            case "datetime":
                const datetime = new Date(val)
                const year = datetime.getFullYear()
                const month = (datetime.getMonth() + 1) < 10 ? "0" + (datetime.getMonth() + 1) : (datetime.getMonth() + 1)
                const day = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate()
                const hour = datetime.getHours() < 10 ? "0" + datetime.getHours() : datetime.getHours()
                const minute = datetime.getMinutes() < 10 ? "0" + datetime.getMinutes() : datetime.getMinutes()
                const second = "00"

                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second
            default: return val
        }
    }
    static buildIcon = icon => {
        switch (icon) {
            case "mail":
                return <MailIcon />
            case "password":
                return <PwdIcon />
            default: break
        }
    }

    static handleChange = (rows, setValues, attribute, returnType, val, strict = false) => {
        let value = FormBuilder.buildVal(returnType, val)
        let type = ""
        let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int")
        let index = value

        if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
            value = filtered[0].list[index].id !== null ? parseInt(filtered[0].list[index].id) : null

            if (filtered[0].list[index].type !== undefined) {
                type = filtered[0].list[index].type

                setValues(prev => ({
                    ...prev,
                    [attribute]: value,
                    [attribute.replace("_id", "_type")]: type
                }))
            }
            else {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value
                }))
            }
        }
        else {
            filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "string")

            if (filtered.length > 0) {
                if (filtered[0].list.filter(_ => _.value === value && _.id !== undefined && _.id === null).length > 0)
                    value = null
            }

            setValues(prev => ({
                ...prev,
                [attribute]: value
            }))
        }

        return {
            value: value,
            type: type,
            filtered: filtered,
            index: index
        }
    }
    static returnUpdates = (object, datas, compare = true) => {
        let dataKeys = Object.keys(datas)
        let key = ""
        let updates = {}

        for(let i in dataKeys) {
            key = dataKeys[i]
            //console.log("CHECK UPDATE", key)

            if (object[key] !== undefined) {
                if (compare) {
                    //console.log("COMPARE", typeof datas[key], object[key], datas[key])

                    switch (typeof datas[key]) {
                        case "string":
                            if (object[key] === null && datas[key].length === 0)
                                continue
                            else if (object[key] !== datas[key] && datas[key] !== null)
                                Reflect.set(updates, key, datas[key])

                            break

                        case "object":
                            if (object[key] === null || (object[key].length === 0 && datas[key].length === 0))
                                continue
                            else if (!this.isEqual(object[key], datas[key]) && datas[key] !== null)
                                Reflect.set(updates, key, datas[key])

                            break

                        default:
                            if (object[key] !== datas[key] && datas[key] !== null)
                                Reflect.set(updates, key, datas[key])

                            break
                    }
                }
                else {
                    //console.log("NOT COMPARE", typeof datas[key], object[key], datas[key])

                    if (datas[key] === null)
                        continue

                    switch (typeof datas[key]) {
                        case "string":
                            if (object[key] === null && datas[key].length === 0)
                                continue

                            break

                        default: break
                    }

                    Reflect.set(updates, key, datas[key])
                }
            }
        }

        return updates
    }
    static isEqual = (obj1, obj2) => {
        if (
            (obj1 === null && obj2 !== null)
            || (obj1 !== null && obj2 === null)
        )
            return false

        let props1 = Object.getOwnPropertyNames(obj1)
        let props2 = Object.getOwnPropertyNames(obj2)

        if (props1.length !== props2.length)
            return false;

        for (let i = 0; i < props1.length; i++) {
            let val1 = obj1[props1[i]]
            let val2 = obj2[props1[i]]
            let isObjects = this.isObject(val1) && this.isObject(val2)

            if (isObjects && !this.isEqual(val1, val2) || !isObjects && val1 !== val2)
                return false
        }

        return true
    }
    static isObject = object => {
        return object != null && typeof object === 'object'
    }
}

export default FormBuilder
