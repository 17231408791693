import Rest from "../../../class/tool/Rest";
import Domain from "../../_skill/Domains/Domain";
import Establishment from "./Establishment";
import stringTools from "../../../class/tool/StringTools";

class EstablishmentController {
    #_rest = new Rest();
    _callback = null;
    _baseURL = "/establishments";

    checkName = value => {
        if (value !== null && value.length > 0)
            return true;

        return false;
    }
    checkAddress = value => {
        if (value !== null && value.length > 0)
            return true;

        return false;
    }
    checkPostalCode = value => {
        if (value !== null && value.length > 0)
            return true;

        return false;
    }
    checkCity = value => {
        if (value !== null && value.length > 0)
            return true;

        return false;
    }
    checkCountry = value => {
        if (value !== null && value.length > 0)
            return true;

        return false;
    }
    checkMinimumDatas(values, type) {
        return (
            this.checkName(values.name)
            && this.checkAddress(values.address)
            && this.checkPostalCode(values.postalCode)
            && this.checkCity(values.city)
            && this.checkCountry(values.country)
        );
    }

    setFormValues(object, setValues) {
        setValues(prev => ({
            ...prev,
            name: object.name !== null ? stringTools.capitalizeFirstLetter(object.name.toLocaleLowerCase()) : "",
            address: object.address !== null ? stringTools.capitalizeFirstLetter(object.address.toLocaleLowerCase()) : "",
            postalCode: object.postalCode !== null ? stringTools.capitalizeFirstLetter(object.postalCode.toLocaleLowerCase()) : "",
            city: object.city !== null ? stringTools.capitalizeFirstLetter(object.city.toLocaleLowerCase()) : "",
            country: object.country !== null ? stringTools.capitalizeFirstLetter(object.country.toLocaleLowerCase()) : ""
        }))
    }
    setFormRows(setRows, formNew = false) {
        let rows

        if (formNew) {
            rows = [
                {
                    label: "Nom",
                    attribute: "name",
                    inputType: "text",
                    returnType: "string",
                    idWrapper: "nameInput",
                    classnameWrapper: "flex paddingBottom",
                    classnameLabel: "",
                    classnameInput: "",
                    placeholder: "Domaine"
                },
                {
                    label: "Adresse",
                    attribute: "address",
                    inputType: "text",
                    returnType: "string",
                    idWrapper: "nameInput",
                    classnameWrapper: "flex paddingBottom",
                    classnameLabel: "",
                    classnameInput: "",
                    placeholder: "Adresse"
                },
                {
                    label: "Code postal",
                    attribute: "postalCode",
                    inputType: "text",
                    returnType: "string",
                    idWrapper: "nameInput",
                    classnameWrapper: "flex paddingBottom",
                    classnameLabel: "",
                    classnameInput: "",
                    placeholder: "Code postal"
                },
                {
                    label: "Ville",
                    attribute: "city",
                    inputType: "text",
                    returnType: "string",
                    idWrapper: "nameInput",
                    classnameWrapper: "flex paddingBottom",
                    classnameLabel: "",
                    classnameInput: "",
                    placeholder: "Ville"
                },
                {
                    label: "Pays",
                    attribute: "country",
                    inputType: "text",
                    returnType: "string",
                    idWrapper: "nameInput",
                    classnameWrapper: "flex paddingBottom",
                    classnameLabel: "",
                    classnameInput: ""
                }
            ]
        }
        else {
            rows = [
                {
                    label: "Nom",
                    attribute: "name",
                    inputType: "text",
                    returnType: "string",
                    idWrapper: "nameInput",
                    classnameWrapper: "flex paddingBottom",
                    classnameLabel: "",
                    classnameInput: "",
                    placeholder: "Domaine"
                },
                {
                    label: "Adresse",
                    attribute: "address",
                    inputType: "text",
                    returnType: "string",
                    idWrapper: "nameInput",
                    classnameWrapper: "flex paddingBottom",
                    classnameLabel: "",
                    classnameInput: "",
                    placeholder: "Adresse"
                },
                {
                    label: "Code postal",
                    attribute: "postalCode",
                    inputType: "text",
                    returnType: "string",
                    idWrapper: "nameInput",
                    classnameWrapper: "flex paddingBottom",
                    classnameLabel: "",
                    classnameInput: "",
                    placeholder: "Code postal"
                },
                {
                    label: "Ville",
                    attribute: "city",
                    inputType: "text",
                    returnType: "string",
                    idWrapper: "nameInput",
                    classnameWrapper: "flex paddingBottom",
                    classnameLabel: "",
                    classnameInput: "",
                    placeholder: "Ville"
                },
                {
                    label: "Pays",
                    attribute: "country",
                    inputType: "text",
                    returnType: "string",
                    idWrapper: "nameInput",
                    classnameWrapper: "flex paddingBottom",
                    classnameLabel: "",
                    classnameInput: ""
                }
            ]
        }

        setRows(rows)
    }
    returnPostDatas(object, datas) {
        let datasToPost = {}
        let needed = ['name', 'address', 'postalCode', 'city', 'country']
        let optionals = []

        for (let i in needed) {
            datasToPost[needed[i]] = datas[needed[i]]
        }

        for (let i in optionals) {
            if (datas[optionals[i]] === null) continue

            switch (typeof datas[optionals[i]]) {
                case "string":
                    if (datas[optionals[i]].length === 0) continue
                    break
                default: break
            }

            datasToPost[optionals[i]] = datas[optionals[i]]
        }

        return datasToPost
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        let dataKeys = Object.keys(datas);
        let key = "";
        let updates = {};

        for(let i in dataKeys) {
            key = dataKeys[i];

            if (datas[key] !== undefined) {
                if (compare) {
                    if (Reflect.get(object, key) !== datas[key] && datas[key] !== null && (datas[key].length > 0 || datas[key] >= 0))
                        Reflect.set(updates, key, datas[key]);
                }
                else {
                    if (datas[key] !== null)
                        Reflect.set(updates, key, datas[key]);
                }
            }
        }

        return updates;
    }

    show(id) {
        this.#_rest.sendQuery(
            'GET',
            this._baseURL + '/' + id,
            {},
            this.handleShow);
    }
    handleShow = (response, error, status = 200) => {
        const object = !error ? new Establishment(response.data) : null;
        this._callback(object, error, status);
    }

    post(datas) {
        this.#_rest.sendQuery(
            'POST',
            this._baseURL,
            datas,
            this.handlePost);
    }
    handlePost = (response, error, status) => {
        this._callback(response, error, status);
    }

    put(object, datas) {
        this.#_rest.sendQuery(
            'PUT',
            this._baseURL + '/' + object.id,
            datas,
            this.handlePut);
    }
    handlePut = (response, error, status) => {
        this._callback(response, error, status);
    }

    delete(object) {
        this.#_rest.sendQuery(
            'DELETE',
            this._baseURL + '/' + object.id,
            {},
            this.handleDelete);
    }
    handleDelete = (response, error, status) => {
        this._callback(response, error, status);
    }
}

export default EstablishmentController;
