import React, {useContext} from "react";
import AppContext from "../../../context/AppContext";
import UnSortingIcon from "../../icons/UnsortingIcon";
import UpSortingIcon from "../../icons/UpSortingIcon";
import DownSortingIcon from "../../icons/DownSortingIcon";
import FormBuilder from "../../../class/tool/FormBuilder";
import "../../../css/Table.css";
import EditIcon from "../../icons/EditIcon";
import LoaderCircle from "../../loader/LoaderCircle";

const Table = () => {
    const { actions, filterValues, filters, setFilterValues, pagination, sortOn, setSortOn, sortValue, setSortValue, header, list, loading } = useContext(AppContext);
    const { setOverBoxForm } = useContext(AppContext);

    const buildListActions = () => {
        if (actions.length === 0)
            return <></>;

        return <div className={"containerActions"}>
            {
                pagination !== null
                && <div className={"pagination"}>
                    <p className={"title"}><strong>{ pagination.total }</strong>{ pagination.title }</p>

                </div>
            }
            {
                actions.length > 0
                && <div className={"actions"}>
                    {
                        actions.map((action, index) => buildAction(action, index))
                    }
                </div>
            }
        </div>;
    }
    const buildAction = (action, key) => {
        switch (action.type) {
            case "button":
                return <div key={key} className={"button"} onClick={action.action}>
                    {
                        (action.icon !== undefined && action.icon !== null)
                        && action.icon
                    }
                    <p className={"text"}>{ action.label }</p>
                </div>;
            default:
                break;
        }
    }
    const buildListFilters = () => {
        if (filters.length === 0)
            return <></>;

        return <div className={"containerFilters"}>
            {
                filters.map((filter, index) => buildFilter(filter, index))
            }
        </div>;
    }
    const buildFilter = (filter, key) => {
        switch (filter.inputType) {
            case "text":
                return FormBuilder.buildInputByType(filter, filterValues, [], changeFilter, null, null, null, null, null, null, false);
            default:
                break;
        }
    }
    const changeFilter = (attribute, returnType, val, strict) => {
        FormBuilder.handleChange(filters, setFilterValues, attribute, returnType, val, strict);
    }
    const buildTable = () => {
        return <>
            <div className={"containerTable"}>
                <table>
                    <thead>
                    {
                        buildHeader()
                    }
                    </thead>
                </table>
            </div>
            <div className={"containerTable"}>
                <table>
                    <tbody>
                    {
                        loading
                            ? buildLoading()
                            : (
                                list.length > 0
                                    ? list.map((line, index) => buildLine(line, index))
                                    : buildEmpty()
                            )
                    }
                    </tbody>
                </table>
            </div>
        </>
    }
    const buildHeader = () => {
        return <tr>
            {
                header.map((column, index) => buildHeadColumn(column, index))
            }
        </tr>
    }
    const buildHeadColumn = (column, index) => {
        return <th key={index} className={(column.class !== undefined ? " " + column.class : "" )}>
            <div className={"header" + (column.sorting ? " sorting" : "")} onClick={column.sorting ? () => { clickSorting(column.attribute) } : ()=>{}}>
                {
                    column.title !== null && <p className={"text"}>{column.title}</p>
                }
                {
                    column.sorting && buildSorting(column)
                }
            </div>
        </th>;
    }
    const buildSorting = column => {
        if (sortOn !== null && sortOn === column.attribute) {
            if (sortValue !== null && sortValue === "asc") {
                return <UpSortingIcon className={"sortIcon asc"} />
            }
            else {
                return <DownSortingIcon className={"sortIcon asc"} />
            }
        }
        else
            return <UnSortingIcon className={"sortIcon"} />
    }
    const buildLoading = () => {
        return <tr>
            <td className={"loading"} colSpan={header.length}>
                <LoaderCircle classname="loader table" strokeWidth="8" stroke="#2b4d66" />
            </td>
        </tr>
    }
    const buildLine = (line, index) => {
        return <tr key={index}>
            {
                header.map((column, index) => (
                    <td key={index} className={(column.class !== undefined ? column.class : "")}>
                        {
                            buildCell(line, column)
                        }
                    </td>
                ))
            }
        </tr>;
    }
    const buildEmpty = () => {
        return <tr>
            <td className={"empty"} colSpan={header.length}>Vide</td>
        </tr>;
    }
    const buildCell = (line, column) => {
        if (column.attribute !== null)
            return line[column.attribute];
        else {
            if (column.class !== undefined && column.class !== null) {
                switch (column.class) {
                    case "openable":
                        return <div className={"openable"} onClick={() => {
                            setOverBoxForm({
                                action: "update",
                                type: line.object_type,
                                object: line
                            })
                        }}>
                            <EditIcon />
                            <p>Éditer</p>
                        </div>;
                    default:
                        break;
                }
            }
        }
    }
    const clickSorting = sortAttribute => {
        if (sortAttribute === sortOn) {
            setSortValue(sortValue === "asc" ? "desc" : "asc");
        }
        else {
            setSortOn(sortAttribute);
            setSortValue("asc");
        }
    }

    return (
        <div className={"table"}>
            {
                buildListActions()
            }
            {
                buildListFilters()
            }
            {
                buildTable()
            }
        </div>
    )
}

export default Table;