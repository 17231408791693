import React from "react";

const UnSortingIcon = props => {
    const { fill, className, handleClick } = props;

    return (
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill={fill} className={className !== undefined ? className : ""} onClick={handleClick !== null ? handleClick : () => {}}>
            <path d="m7 13h18a1 1 0 0 0 .707-1.707l-9-9a.9994.9994 0 0 0 -1.414 0l-9 9a1 1 0 0 0 .707 1.707z"></path>
            <path d="m25 19h-18a1 1 0 0 0 -.707 1.707l9 9a.9995.9995 0 0 0 1.414 0l9-9a1 1 0 0 0 -.707-1.707z"></path>
        </svg>
    );
}

export default UnSortingIcon;
