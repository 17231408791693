import React, {useState, useEffect} from "react";
import LoaderCircle from "../../loader/LoaderCircle";
import FormBuilder from "../../../class/tool/FormBuilder";
import UserController from "../../../stories/_account/Users/UserController";
import '../../../css/form/Form.css';

const FormPwd = props => {
    const controller = new UserController()

    const { object, closeView } = props
    const [ values, setValues ] = useState({
        oldPassword: "",
        newPassword: "",
        newPassword_confirmation: ""
    })
    const [ errors, setErrors ] = useState([])
    const [ saving, setSaving ] = useState(false)
    const [ disabled, setDisabled ] = useState(true)
    const [ globalError, setGlobalError ] = useState(null)
    const [ rows, setRows ] = useState([])
    const [ resultsPwd, setResultsPwd ] = useState({
        noWhitespace: false,
        isContainsUppercase: false,
        isContainsLowercase: false,
        isContainsNumber: false,
        isContainsSymbol: false,
        isValidLength: false
    })

    const initRows = () => {
        if (object.definePwd) {
            setRows([
                {
                    label: "Nouveau mot de passe",
                    attribute: "newPassword",
                    inputType: "password",
                    returnType: "string",
                    idWrapper: "",
                    classnameWrapper: "flex",
                    classnameLabel: "",
                    classnameInput: "",
                    placeholder: "••••••••••",
                    emptyText: "Aucun",
                    autoComplete: "new-password"
                },
                {
                    label: "Confirmation",
                    attribute: "newPassword_confirmation",
                    inputType: "password",
                    returnType: "string",
                    idWrapper: "",
                    classnameWrapper: "flex",
                    classnameLabel: "",
                    classnameInput: "",
                    placeholder: "••••••••••",
                    emptyText: "Aucun",
                    autoComplete: "off"
                }
            ])
        }
        else {
            setRows([
                {
                    label: "Ancien mot de passe",
                    attribute: "oldPassword",
                    inputType: "password",
                    returnType: "string",
                    idWrapper: "",
                    classnameWrapper: "flex",
                    classnameLabel: "",
                    classnameInput: "",
                    placeholder: "••••••••••",
                    emptyText: "Aucun",
                    autoComplete: "off"
                },
                {
                    label: "Nouveau mot de passe",
                    attribute: "newPassword",
                    inputType: "password",
                    returnType: "string",
                    idWrapper: "",
                    classnameWrapper: "flex",
                    classnameLabel: "",
                    classnameInput: "",
                    placeholder: "••••••••••",
                    emptyText: "Aucun",
                    autoComplete: "new-password"
                },
                {
                    label: "Confirmation",
                    attribute: "newPassword_confirmation",
                    inputType: "password",
                    returnType: "string",
                    idWrapper: "",
                    classnameWrapper: "flex",
                    classnameLabel: "",
                    classnameInput: "",
                    placeholder: "••••••••••",
                    emptyText: "Aucun",
                    autoComplete: "off"
                }
            ])
        }
    }
    const handleChange = (attribute, returnType, val, strict = false) => {
        let value = FormBuilder.buildVal(returnType, val);
        let filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "int");
        let index = value;

        if (!strict && filtered.length > 0 && filtered[0].list.length > 0) {
            value = parseInt(filtered[0].list[index].id);

            if (filtered[0].list[index].type !== undefined && values[attribute.replace("_id", "_type")] !== undefined) {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value,
                    [attribute.replace("_id", "_type")]: filtered[0].list[index].type
                }));
            }
            else {
                setValues(prev => ({
                    ...prev,
                    [attribute]: value
                }));
            }
        }
        else {
            filtered = rows.filter(row => row.attribute === attribute && row.inputType === "select" && row.returnType === "string")

            if (filtered.length > 0) {
                if (filtered[0].list.filter(_ => _.value === value && _.id !== undefined && _.id === null).length > 0)
                    value = null
            }

            setValues(prev => ({
                ...prev,
                [attribute]: value
            }))
        }

        switch (attribute) {
            case "newPassword":
                evolutionPwd(value)
                break
            case "newPassword_confirmation":
                confirmationPwd(value)
                break
            default: break
        }
    }
    const callToSave = () => {
        setGlobalError(null);
        reinitAllEdits();
        save();
    }
    const reinitAllEdits = () => {
        setErrors([]);
    }
    const returnUpdates = () => {
        return controller.returnUpdatesFromCompare(object, values);
    }
    const defineErrors = (type, empty) => {
        let errorsTmp = errors.slice()

        switch (type) {
            case "oldPassword":
                if(empty) errorsTmp["oldPassword"] = "Vous devez saisir votre ancien mot de passe";
                else errorsTmp["oldPassword"] = "Le mot de passe est incorrect";
                break;
            case "newPassword":
                if(empty) errorsTmp["newPassword"] = "Vous devez saisir un nouveau mot de passe";
                else errorsTmp["newPassword"] = "Le mot de passe ne respecte pas le bon format attendu";
                break;
            case "newPassword_confirmation":
                if(empty) errorsTmp["newPassword_confirmation"] = "Vous devez saisir la confirmation du nouveau mot de passe";
                else errorsTmp["newPassword_confirmation"] = "La confirmation ne correspond pas";
                break;
            default: break;
        }

        setErrors(errorsTmp);
    }
    const save = () => {
        if (disabled) return;
        if (saving) return;

        setSaving(true);

        let datas = {
            "password": values.newPassword,
            "password_confirmation": values.newPassword_confirmation,
        };

        if (!object.definePwd)
            datas.oldPassword = values.oldPassword;

        controller._callback = handleReturnSave;
        controller.resetPwd(object, datas);
    }
    const handleReturnSave = (response, error, status) => {
        setSaving(false);

        switch (status) {
            case 201:
                let user = JSON.parse(localStorage.getItem("user"))
                user.definePwd = 0
                localStorage.setItem("user", JSON.stringify(user))

                closeView()
                break;
            case 422:
                setGlobalError("Certaines données sont incorrectes.");
                break;
            default:
                setGlobalError("Une erreur s'est produite lors de l'enregistrement");
                break;
        }
    }
    const handleSubmit = event => {
        if (event !== undefined)
            event.preventDefault();

        callToSave();
    }
    const evolutionPwd = value => {
        const results = checkPassword(value)
        setResultsPwd(results)
    }
    const checkPassword = value => {
        const results = {
            noWhitespace: true,
            isContainsUppercase: true,
            isContainsLowercase: true,
            isContainsNumber: true,
            isContainsSymbol: true,
            isValidLength: true
        }
        const isWhitespace = /^(?=.*\s)/
        const isContainsUppercase = /^(?=.*[A-Z])/
        const isContainsLowercase = /^(?=.*[a-z])/
        const isContainsNumber = /^(?=.*[0-9])/
        const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/
        const isValidLength = /^.{10,}$/

        if (isWhitespace.test(value)) results.noWhitespace = false
        if (!isContainsUppercase.test(value)) results.isContainsUppercase = false
        if (!isContainsLowercase.test(value)) results.isContainsLowercase = false
        if (!isContainsNumber.test(value)) results.isContainsNumber = false
        if (!isContainsSymbol.test(value)) results.isContainsSymbol = false
        if (!isValidLength.test(value)) results.isValidLength = false

        return results;
    }
    const confirmationPwd = value => {
        let result = resultsPwd.noWhitespace
            && resultsPwd.isContainsUppercase
            && resultsPwd.isContainsUppercase
            && resultsPwd.isContainsUppercase
            && resultsPwd.isContainsUppercase
            && resultsPwd.isContainsUppercase

        if (result && value === values.newPassword)
            setDisabled(false)
        else
            setDisabled(true)
    }

    useEffect(() => {
        initRows()
    }, [])

    return (
        <div className="overlayer">
            <div className="wrapOverbox">
                <div className="overbox smallWidthAutoHeight paddingBox">
                    <form className="form" onSubmit={handleSubmit}>
                        {
                            object.definePwd === 1
                            && <p className="titleForm center marginBottom">Définir un mot de passe</p>
                        }
                        {
                            globalError !== null
                            && <p className="error">{globalError}</p>
                        }
                        <div className={"wrapper"}>
                            {
                                rows.map((row, index) => (
                                    <div key={index}
                                         id={row.idWrapper}
                                         className={"containerInput" + (row.classnameWrapper !== undefined && row.classnameWrapper.length > 0 ? " " + row.classnameWrapper : "")}>
                                        {
                                            (row.icon !== undefined && row.icon.length > 0)
                                            && <div className={"icon"}>
                                                {
                                                    FormBuilder.buildIcon(row.icon)
                                                }
                                            </div>
                                        }
                                        <div className={"input" + ((row.icon === undefined || row.icon.length === 0) ? " withoutIcon" : "")}>
                                            <label
                                                className={"label" + (row.classnameLabel !== undefined && row.classnameLabel.length > 0 ? " " + row.classnameLabel : "")}>{row.label}</label>
                                            {
                                                FormBuilder.buildInputByType(row, values, errors, handleChange, null, null, handleSubmit, null, null, null, index === 0)
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <ul className="checkPwd">
                            <li className={"item " + (values.newPassword.length > 0 ? (resultsPwd.noWhitespace ? "ok" : "nok") : "")}>Ne
                                doit pas contenir d'espaces
                            </li>
                            <li className={"item " + (values.newPassword.length > 0 ? (resultsPwd.isContainsUppercase ? "ok" : "nok") : "")}>Doit
                                contenir au moins une lettre majuscule
                            </li>
                            <li className={"item " + (values.newPassword.length > 0 ? (resultsPwd.isContainsLowercase ? "ok" : "nok") : "")}>Doit
                                contenir au moins une lettre minuscule
                            </li>
                            <li className={"item " + (values.newPassword.length > 0 ? (resultsPwd.isContainsNumber ? "ok" : "nok") : "")}>Doit
                                contenir au moins un chiffre
                            </li>
                            <li className={"item " + (values.newPassword.length > 0 ? (resultsPwd.isContainsSymbol ? "ok" : "nok") : "")}>Doit
                                contenir au moins un caractère spécial
                            </li>
                            <li className={"item " + (values.newPassword.length > 0 ? (resultsPwd.isValidLength ? "ok" : "nok") : "")}>Doit
                                contenir au moins 10 caractères
                            </li>
                            <li className={"item " + (values.newPassword_confirmation.length > 0 ? (values.newPassword === values.newPassword_confirmation ? "ok" : "nok") : "")}>Confirmer
                                le nouveau mot de passe
                            </li>
                        </ul>
                        <div className={"submit right" + (disabled ? " disable" : "") + (saving ? " hide" : "")} onClick={callToSave}>
                            {
                                saving
                                    ? <LoaderCircle classname="loader submitForm" strokeWidth="8" stroke="#FFFFFF" />
                                    : "Enregistrer"
                            }
                        </div>
                        {
                            object.definePwd === 0
                            && <div className="cancel align" onClick={closeView}>Fermer</div>
                        }
                        <div className="clearing"/>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default FormPwd;
