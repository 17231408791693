import React from "react";

const SchoolIcon = props => {
    const { fill } = props;

    return (
        <svg
            height="1em"
            viewBox="0 0 512 512"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M321.889 69.026h-58.477V7.611h58.477c6.728 0 12.181 5.454 12.181 12.181v37.052c0 6.728-5.454 12.182-12.181 12.182z"
                fill="#78d090"
            />
            <path
                d="M263.407 118.748a7.612 7.612 0 0 1-7.612-7.611V7.611c0-4.204 3.407-7.611 7.612-7.611s7.611 3.408 7.611 7.611v103.525a7.61 7.61 0 0 1-7.611 7.612z"
                fill="#595959"
            />
            <path d="M53.753 263.171V512h419.318V263.171" fill="#eab14d"/>
            <path
                d="M409.493 444.793h20.508c9.161 0 16.593-7.432 16.593-16.593v-89.87c0-9.161-7.432-16.593-16.593-16.593h-20.508"
                fill="#d3effb"
            />
            <path
                d="M430.001 452.404h-38.05a7.612 7.612 0 1 1 0-15.223h38.05c4.952 0 8.982-4.029 8.982-8.982v-89.87c0-4.953-4.03-8.982-8.982-8.982h-38.05a7.612 7.612 0 1 1 0-15.223h38.05c13.347 0 24.205 10.858 24.205 24.205v89.87c0 13.347-10.858 24.205-24.205 24.205z"
                fill="#c32430"
            />
            <path
                d="M117.331 321.736H96.824c-9.161 0-16.593 7.432-16.593 16.593v89.87c0 9.161 7.432 16.593 16.593 16.593h20.508"
                fill="#d3effb"
            />
            <path
                d="M80.23 435.811a7.61 7.61 0 0 1-7.611-7.611v-89.87c0-13.347 10.858-24.205 24.204-24.205h50.128a7.612 7.612 0 0 1 0 15.223H96.823c-4.952 0-8.981 4.029-8.981 8.982v89.87a7.612 7.612 0 0 1-7.612 7.611zM117.331 275.972H30.368l51.58-142.182h362.928l51.58 142.182h-86.963"
                fill="#c32430"
            />
            <path
                d="M409.493 275.972V512H117.331V275.972l135.858-153.305 10.218-11.53 10.218 11.53z"
                fill="#f6e266"
            />
            <path
                d="M409.493 275.972v60.222c-7.026 0-14.001-2.888-19.023-8.561L263.407 184.271 136.354 327.633c-5.023 5.673-11.998 8.561-19.023 8.561v-60.222l135.858-153.305c3.192-1.393 6.66-2.145 10.218-2.145s7.036.752 10.218 2.145z"
                fill="#eab14d"
            />
            <path
                d="M409.501 301.392c-7.021 0-14.009-2.892-19.032-8.56l-127.057-143.37-127.057 143.37c-9.311 10.507-25.377 11.475-35.882 2.164-10.506-9.311-11.475-25.376-2.165-35.882l146.08-164.836a25.418 25.418 0 0 1 38.046 0l146.08 164.836c9.311 10.506 8.341 26.571-2.165 35.882a25.316 25.316 0 0 1-16.848 6.396z"
                fill="#da4a54"
            />
            <circle cx={263.412} cy={237.868} fill="#d3effb" r={36.762}/>
            <path
                d="M263.411 282.241c-24.467 0-44.373-19.906-44.373-44.373s19.906-44.373 44.373-44.373c24.468 0 44.374 19.906 44.374 44.373s-19.906 44.373-44.374 44.373zm0-73.524c-16.073 0-29.15 13.077-29.15 29.15s13.076 29.15 29.15 29.15c16.073 0 29.151-13.077 29.151-29.15s-13.077-29.15-29.151-29.15z"
                fill="#da4a54"
            />
            <path
                d="M226.279 444.793H185.27c-9.165 0-16.595-7.43-16.595-16.595v-89.866c0-9.165 7.43-16.595 16.595-16.595h41.009c9.165 0 16.595 7.43 16.595 16.595v89.866c0 9.165-7.43 16.595-16.595 16.595zM341.553 444.793h-41.009c-9.165 0-16.595-7.43-16.595-16.595v-89.866c0-9.165 7.43-16.595 16.595-16.595h41.009c9.165 0 16.595 7.43 16.595 16.595v89.866c0 9.165-7.429 16.595-16.595 16.595z"
                fill="#d3effb"
            />
            <g fill="#da4a54">
                <path
                    d="M341.549 314.124h-41.005c-13.347 0-24.205 10.858-24.205 24.205v26.673c-.001.033-.005.065-.005.098s.004.065.005.098V428.2c0 13.346 10.858 24.205 24.205 24.205h41.005c13.352 0 24.215-10.858 24.215-24.205v-89.87c0-13.347-10.863-24.206-24.215-24.206zm-41.005 15.223h41.005c4.958 0 8.992 4.029 8.992 8.982v19.159h-58.979v-19.159c0-4.952 4.03-8.982 8.982-8.982zm41.005 107.834h-41.005c-4.952 0-8.982-4.029-8.982-8.982v-55.488h58.979V428.2c0 4.952-4.034 8.981-8.992 8.981zM250.486 365.002v-26.67c0-13.348-10.859-24.207-24.207-24.207h-41.008c-13.348 0-24.207 10.859-24.207 24.207v89.866c0 13.348 10.859 24.207 24.207 24.207h41.008c13.348 0 24.207-10.859 24.207-24.207v-63c.001-.033.005-.065.005-.098s-.005-.065-.005-.098zm-65.215-35.654h41.008c4.953 0 8.984 4.03 8.984 8.984v19.157h-58.976v-19.157c-.001-4.954 4.03-8.984 8.984-8.984zm41.007 107.834H185.27c-4.953 0-8.984-4.03-8.984-8.984v-55.487h58.976v55.487c.001 4.954-4.03 8.984-8.984 8.984z"/>
            </g>
            <path
                d="M219.117 467.253c0 24.707-20.04 44.747-44.747 44.747H60.291c-24.707 0-44.747-20.04-44.747-44.747 0-24.717 20.04-44.757 44.747-44.757 2.064 0 4.087.153 6.07.427 10.279-17.458 29.252-29.171 50.969-29.171 21.718 0 40.69 11.713 50.97 29.171a44.13 44.13 0 0 1 6.07-.427c24.707 0 44.747 20.04 44.747 44.757z"
                fill="#78d090"
            />
            <path
                d="M96.823 444.793S57.709 465.169 85.527 512H60.291c-24.707 0-44.747-20.04-44.747-44.747 0-24.717 20.04-44.757 44.747-44.757 2.064 0 4.087.153 6.07.427 10.279-17.458 29.252-29.171 50.969-29.171-24.838 0-33.064 25.449-20.507 51.041z"
                fill="#66b49d"
            />
            <path
                d="M271.693 245.479h-8.281a7.61 7.61 0 0 1-7.611-7.611v-14.591a7.612 7.612 0 0 1 15.223 0v6.98h.67a7.61 7.61 0 1 1-.001 15.222z"
                fill="#595959"
            />
        </svg>
    );
}

export default SchoolIcon;
