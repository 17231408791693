import React, {useContext, useEffect, useState} from "react";
import $ from "jquery";
import LoaderCircle from "../../loader/LoaderCircle";
import EditIcon from "../../icons/EditIcon";
import StringTools from "../../../class/tool/StringTools";
import AppContext from "../../../context/AppContext";
import "../../../css/Menu.css";
import AddIcon from "../../icons/AddIcon";

const Menu = () => {
    const {items, loading, editing, setOverBoxForm, link} = useContext(AppContext);
    const [choiceSubs, setChoiceSubs] = useState(null);
    let colors = [
        "#0E2F7A", "#3E8EB4", "#E36738", "#AE496F", "#5F5FF3", "#298F84", "#6F5B32", "#C29210", "#333145", "#3D6E60",
        "#0E2F7A", "#3E8EB4", "#E36738", "#AE496F", "#5F5FF3", "#298F84", "#6F5B32", "#C29210", "#333145", "#3D6E60",
        "#0E2F7A", "#3E8EB4", "#E36738", "#AE496F", "#5F5FF3", "#298F84", "#6F5B32", "#C29210", "#333145", "#3D6E60",
        "#0E2F7A", "#3E8EB4", "#E36738", "#AE496F", "#5F5FF3", "#298F84", "#6F5B32", "#C29210", "#333145", "#3D6E60",
        "#0E2F7A", "#3E8EB4", "#E36738", "#AE496F", "#5F5FF3", "#298F84", "#6F5B32", "#C29210", "#333145", "#3D6E60"
    ];

    const animation = () => {
        let ident = ".menu > .item";
        let duration = 150;

        if ($(ident).length > 10)
            duration = 100;
        else if ($(ident).length > 7)
            duration = 120;

        $(ident).each(function(index) {
            setTimeout(() => { $(this).addClass("appear"); }, index*duration);
        });
    }
    const clickItem = item => {
        if (!editing && item.link !== undefined && item.link !== null) {
            link(item.link, item.object !== undefined ? item.object : null);
        }
        else {
            if (item.add !== undefined) {
                setOverBoxForm({
                    action: "add",
                    type: item.type,
                    object: null
                });
            }
            else {
                switch (item.object.object_type) {
                    case "ability":
                        if (item.object.nbSubAbilities === 0) {
                            setChoiceSubs(item);
                            return;
                        }

                        break;
                    default: break;
                }

                setOverBoxForm({
                    action: "update",
                    type: item.object.object_type,
                    object: item.object
                });
            }
        }
    }
    const contentChoice = () => {
        if (choiceSubs === null)
            return;

        switch (choiceSubs.object.object_type) {
            case "ability":
                return <>
                    <p className={"title"}>Que souhaites-tu faire avec cette compétence ?</p>
                    <div className={"containerChoices"}>
                        <div className={"choice"} onClick={() => { clickChoice("update") }}>
                            <EditIcon className={"icon"} />
                            <p className={"text"}>Modifier la compétence</p>
                        </div>
                        <div className={"choice"} onClick={() => { clickChoice("add") }}>
                            <AddIcon className={"icon"} />
                            <p className={"text"}>Ajouter une sous-compétence</p>
                        </div>
                    </div>
                </>;
            default:
                return;
        }
    }
    const clickChoice = choice => {
        switch (choiceSubs.object.object_type) {
            case "ability":
                setChoiceSubs(null);

                if (choice === "update") {
                    setOverBoxForm({
                        action: "update",
                        type: choiceSubs.object.object_type,
                        object: choiceSubs.object
                    });
                }
                else if (choice === "add") {
                    setOverBoxForm({
                        action: "add",
                        type: "subAbility",
                        object: null,
                        ability_id: choiceSubs.object.id
                    });
                }

                break;
            default:
                break;
        }
    }

    useEffect(() => {
        animation();
    }, [items]);

    return (
        <>
            <div className={"menu"}>
                <LoaderCircle classname={"loader" + (loading ? " visible" : "")} stroke={"#ffffff"} strokeWidth={5}/>
                {
                    items.map((item, index) => (
                        <div key={index}
                             className={"item" + (!editing && item.add !== undefined ? " hidden" : " appear")}
                             onClick={() => {
                                 clickItem(item);
                             }}>
                            <div
                                className={"containerEdit" + ((editing && item.edit !== undefined && item.edit) ? " appear" : "")}>
                                <EditIcon className={"edit"}/></div>
                            {
                                item.icon !== undefined
                                    ? item.icon
                                    : <p className={"iconLetters"}
                                         style={{backgroundColor: colors[index]}}>{StringTools.getFirstChars(item.title)}</p>
                            }
                            <p className={"title"}>{item.title}</p>
                        </div>
                    ))
                }
            </div>
            <div className={"Overlay " + (choiceSubs !== null ? " appear" : "disappear")}>
                <div className={"OverBox confirm " + (choiceSubs !== null ? " appear" : "disappear")}>
                    { contentChoice() }
                </div>
            </div>
        </>
    )
}

export default Menu;