import React from "react"

const LoaderCircle = props => {
    return (
        <svg
            className={props.classname}
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
        >
            <circle
                cx={50}
                cy={50}
                fill="none"
                stroke={props.stroke !== undefined ? props.stroke : "#001c8f"}
                strokeWidth={props.strokeWidth !== undefined ? props.strokeWidth : "10"}
                r={35}
                strokeDasharray="164.93361431346415 56.97787143782138"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1.01010101010101s"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                />
            </circle>
        </svg>
    )
}

export default LoaderCircle
