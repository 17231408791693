import Entity from "../../../class/abstract/Entity";

class Ability extends Entity {
    object_type = "ability";
    domain_id = null;
    name = null;
    description = null;
    nbSubAbilities = 0;

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined) {
                Reflect.set(this, key, datas[key])
            }
        }
    }
}

export default Ability;
