import React, {useContext, useEffect} from "react";
import $ from "jquery";
import AppContext from "../../../context/AppContext";
import "../../../css/ValidMessage.css";

const ValidMessage = props => {
    const { setValid } = useContext(AppContext);

    const cleanError = () => {
        $(".site > .valid").addClass("disappear");

        setTimeout(() => {
            setValid(null);
        }, 500);
    }

    useEffect(() => {
        let timeout = setTimeout(() => { cleanError() }, 3000);

        return() => {
            clearTimeout(timeout);
        }
    }, []);

    return (
        <p className={"valid"}>
            {
                props.message
            }
        </p>
    )
}

export default ValidMessage;