import React, {useEffect} from "react";

const NotFound = () => {
    useEffect(() => {
        document.title = "Calopin - Page introuvable";
    });

    return(
        <div className="404">
            <p>Zut ! Page introuvable</p>
        </div>
    );
}

export default NotFound;
