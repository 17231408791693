import Rest from "../../../class/tool/Rest";
import Level from "./Level";

class LevelController {
    #_rest = new Rest();
    _callback = null;
    _baseURL = "/levels";

    checkName = value => {
        if (value !== null && value.length > 0)
            return true;

        return false;
    }
    checkMinimumDatas(values, type) {
        return (
            this.checkName(values.name)
        );
    }

    setFormValues(object, setValues) {
        setValues(prev => ({
            ...prev,
            name: object.name !== null ? object.name.toLocaleUpperCase() : ""
        }))
    }
    setFormRows(setRows, formNew = false) {
        let rows

        if (formNew) {
            rows = [
                {
                    label: "Nom",
                    attribute: "name",
                    inputType: "text",
                    returnType: "string",
                    idWrapper: "nameInput",
                    classnameWrapper: "flex paddingBottom",
                    classnameLabel: "",
                    classnameInput: "",
                    placeholder: "Domaine"
                }
            ]
        }
        else {
            rows = [
                {
                    label: "Nom",
                    attribute: "name",
                    inputType: "text",
                    returnType: "string",
                    idWrapper: "nameInput",
                    classnameWrapper: "flex paddingBottom",
                    classnameLabel: "",
                    classnameInput: "",
                    placeholder: "Domaine"
                }
            ]
        }

        setRows(rows)
    }
    returnPostDatas(object, datas) {
        let datasToPost = {}
        let needed = ['name']
        let optionals = []

        for (let i in needed) {
            datasToPost[needed[i]] = datas[needed[i]]
        }

        for (let i in optionals) {
            if (datas[optionals[i]] === null) continue

            switch (typeof datas[optionals[i]]) {
                case "string":
                    if (datas[optionals[i]].length === 0) continue
                    break
                default: break
            }

            datasToPost[optionals[i]] = datas[optionals[i]]
        }

        return datasToPost
    }
    returnUpdatesFromCompare(object, datas, compare = true) {
        let dataKeys = Object.keys(datas);
        let key = "";
        let updates = {};

        for(let i in dataKeys) {
            key = dataKeys[i];

            if (datas[key] !== undefined) {
                if (compare) {
                    if (Reflect.get(object, key) !== datas[key] && datas[key] !== null && (datas[key].length > 0 || datas[key] >= 0))
                        Reflect.set(updates, key, datas[key]);
                }
                else {
                    if (datas[key] !== null)
                        Reflect.set(updates, key, datas[key]);
                }
            }
        }

        return updates;
    }

    index() {
        this.#_rest.sendQuery(
            'GET',
            this._baseURL,
            {},
            this.handleIndex);
    }
    handleIndex = (response, error, status = 200) => {
        let listObjects = [];
        let pagination = null;

        if(status === 200) {
            for(let item in response.data)
                listObjects.push(new Level(response.data[item]));
        }

        this._callback(listObjects, error, status);
    }
}

export default LevelController;
