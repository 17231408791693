import React from "react";

const TreeIcon = props => {
    const { fill } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            width="1em"
            height="1em"
            {...props}
        >
            <g data-name="20-tree">
                <path
                    d="m23.72 51.88-5.51-2.36A2.014 2.014 0 0 1 17 47.68V47l-.02-.1A4.981 4.981 0 0 0 20 45l.09-.19a6.689 6.689 0 0 0 2.92 1.11L23 46v3.04a5.987 5.987 0 0 0 .72 2.84ZM32 48.71V51l-2.81.94-.98-.42A2.014 2.014 0 0 1 27 49.68v-4.36a6.948 6.948 0 0 0 .66-.36A7.066 7.066 0 0 0 32 48.71Z"
                    style={{
                        fill: "#fff",
                    }}
                />
                <path
                    d="M34 49a6.935 6.935 0 0 0 4.94-2.05L39 47v3.65a2 2 0 0 1-1.26 1.85L34 54l-4.81-2.06L32 51v-2.29a7.01 7.01 0 0 0 2 .29ZM47 42.28v4.48a2 2 0 0 1-1.11 1.79L43 50v-5l-.07-.23A7.942 7.942 0 0 0 47 42.28Z"
                    style={{
                        fill: "#fff",
                    }}
                />
                <path
                    d="M43 45v5.52a6 6 0 0 1-3.02 5.21L36 58l-2-4 3.74-1.5A2 2 0 0 0 39 50.65V47l-.06-.05a6.877 6.877 0 0 0 1.39-1.98c.22.01.44.03.67.03a8.159 8.159 0 0 0 1.93-.23Z"
                    style={{
                        fill: "#9a6948",
                    }}
                />
                <path
                    d="M29.19 51.94 34 54l2 4 2 5h-8v-7l-3.36-1.44A6.033 6.033 0 0 1 23 49.04V46l.01-.08A5.365 5.365 0 0 0 24 46a7.02 7.02 0 0 0 3-.68v4.36a2.014 2.014 0 0 0 1.21 1.84Z"
                    style={{
                        fill: "#9a6948",
                    }}
                />
                <path
                    d="M26.88 28.65a6.033 6.033 0 0 0-1.8 3.44A7.738 7.738 0 0 0 24 32a7 7 0 0 0-3.91 12.81L20 45a4.981 4.981 0 0 1-3.02 1.9 4.526 4.526 0 0 1-.98.1 5 5 0 0 1-4.67-3.24A4.178 4.178 0 0 1 10 44a4 4 0 0 1-4-4c0-.14.03-.27.04-.4a5 5 0 0 1 0-9.2c-.01-.13-.04-.26-.04-.4a4.961 4.961 0 0 1 7.27-4.43A4.006 4.006 0 0 1 17 23l.08-.01A6.98 6.98 0 0 0 24 29a7.071 7.071 0 0 0 2.65-.52 1.952 1.952 0 0 0 .23.17Z"
                    style={{
                        fill: "#2f5224",
                    }}
                />
                <path
                    d="M52 16a6.976 6.976 0 0 1-2.59 5.43A6.867 6.867 0 0 0 47 21a7 7 0 0 0-7 7 7.455 7.455 0 0 0 .09 1.06 7.912 7.912 0 0 0-3.67 1.38 5.982 5.982 0 0 0-9.54-1.79 1.952 1.952 0 0 1-.23-.17A7.071 7.071 0 0 1 24 29a6.98 6.98 0 0 1-6.92-6.01A5.365 5.365 0 0 1 17 22a6.767 6.767 0 0 1 .08-1.01c-.03 0-.05.01-.08.01a5 5 0 0 1 0-10c.04 0 .07.01.1.01A5.578 5.578 0 0 1 17 10a5 5 0 0 1 5-5 4.893 4.893 0 0 1 1.99.42 6.986 6.986 0 0 1 9.31-2.59A6.986 6.986 0 0 1 45 8a6.531 6.531 0 0 1-.08 1H45a7 7 0 0 1 7 7Z"
                    style={{
                        fill: "#5cb34f",
                    }}
                />
                <path
                    d="M32 48.71a7.066 7.066 0 0 1-4.34-3.75 6.948 6.948 0 0 1-.66.36 7.02 7.02 0 0 1-3 .68 5.365 5.365 0 0 1-.99-.08 6.689 6.689 0 0 1-2.92-1.11A7 7 0 0 1 24 32a7.738 7.738 0 0 1 1.08.09 6.033 6.033 0 0 1 1.8-3.44 5.982 5.982 0 0 1 9.54 1.79 7.912 7.912 0 0 1 3.67-1.38A7.455 7.455 0 0 1 40 28a7 7 0 0 1 7-7 6.867 6.867 0 0 1 2.41.43 4.551 4.551 0 0 1 .51.21A5.5 5.5 0 0 1 58 26.5a5.543 5.543 0 0 1-1.01 3.18 7 7 0 1 1-8.63 10.46A8.1 8.1 0 0 1 47 42.28a7.942 7.942 0 0 1-4.07 2.49A8.159 8.159 0 0 1 41 45c-.23 0-.45-.02-.67-.03A7 7 0 0 1 34 49a7.01 7.01 0 0 1-2-.29Z"
                    style={{
                        fill: "#7bc24f",
                    }}
                />
                <path
                    d="M54 38a7 7 0 0 1-5.64-2.86A8.1 8.1 0 0 1 47 37.28a7.942 7.942 0 0 1-4.07 2.49A8.159 8.159 0 0 1 41 40c-.23 0-.45-.02-.67-.03A7 7 0 0 1 34 44a6.981 6.981 0 0 1-6.34-4.04 6.948 6.948 0 0 1-.66.36 7.02 7.02 0 0 1-3 .68 5.365 5.365 0 0 1-.99-.08 6.689 6.689 0 0 1-2.92-1.11 7.007 7.007 0 0 1-2.626-3.3 6.987 6.987 0 0 0 2.626 8.3 6.689 6.689 0 0 0 2.92 1.11A5.365 5.365 0 0 0 24 46a7.02 7.02 0 0 0 3-.68 6.948 6.948 0 0 0 .66-.36A6.981 6.981 0 0 0 34 49a7 7 0 0 0 6.33-4.03c.22.01.44.03.67.03a8.159 8.159 0 0 0 1.93-.23A7.942 7.942 0 0 0 47 42.28a8.1 8.1 0 0 0 1.36-2.14 6.984 6.984 0 0 0 12.167-6.624A6.989 6.989 0 0 1 54 38Z"
                    style={{
                        fill: "#44853b",
                    }}
                />
                <path
                    d="m57.014 29.693-.045.007a5.652 5.652 0 0 1-5.855 4.8 6.138 6.138 0 0 1-4.743-2.145 6.045 6.045 0 0 1-1.144 1.605 6.864 6.864 0 0 1-3.422 1.868 7.7 7.7 0 0 1-1.623.172c-.194 0-.379-.015-.564-.023a5.18 5.18 0 0 1-1.168 1.485A6.205 6.205 0 0 1 34.3 39a6.533 6.533 0 0 1-1.681-.218 5.793 5.793 0 0 1-3.65-2.812c-.177.1-.362.187-.555.27a6.513 6.513 0 0 1-2.523.51 5.038 5.038 0 0 1-.832-.06 6.054 6.054 0 0 1-2.456-.833 5.341 5.341 0 0 1-2.318-2.781 7.006 7.006 0 0 0-2.816 3.431 7.007 7.007 0 0 0 2.626 3.3 6.689 6.689 0 0 0 2.92 1.11A5.365 5.365 0 0 0 24 41a7.02 7.02 0 0 0 3-.68 6.948 6.948 0 0 0 .66-.36A6.981 6.981 0 0 0 34 44a7 7 0 0 0 6.33-4.03c.22.01.44.03.67.03a8.159 8.159 0 0 0 1.93-.23A7.942 7.942 0 0 0 47 37.28a8.1 8.1 0 0 0 1.36-2.14 6.991 6.991 0 0 0 12.163-1.615 6.97 6.97 0 0 0-3.509-3.832Z"
                    style={{
                        fill: "#5cb34f",
                    }}
                />
                <path
                    d="M23.72 51.88a5.966 5.966 0 0 1-.516-1.309L18.611 48.6a1.014 1.014 0 0 1-.611-.92v-1.11a4.9 4.9 0 0 1-1.02.33 4.526 4.526 0 0 1-.98.1v.68a3.012 3.012 0 0 0 1.816 2.758l5.511 2.362a1 1 0 0 0 .34.08l.779.041a5.946 5.946 0 0 1-.726-1.041ZM31 48.311v1.969l-2.127.712a1 1 0 0 0-.6.555l.916.393 1.383.592 1.744-.584A1 1 0 0 0 33 51v-2.072a6.371 6.371 0 0 1-2-.617ZM47.973 40.869A7.931 7.931 0 0 1 47 42.28a7.807 7.807 0 0 1-1 .949v3.531a1 1 0 0 1-.558.9L43 48.881v1.639c0 .162-.019.32-.032.48H43a.994.994 0 0 0 .448-.106l2.889-1.45A2.986 2.986 0 0 0 48 46.76V41a.823.823 0 0 0-.027-.131Z"
                    style={{
                        fill: "#5d241d",
                    }}
                />
                <path
                    d="M35.64 61a2 2 0 0 1-1.962-1.608L33 56l-7.231-3.616A1.39 1.39 0 0 1 25 51.141v-.75A1.391 1.391 0 0 1 26.391 49H27v-3.68a7.02 7.02 0 0 1-3 .68 5.365 5.365 0 0 1-.99-.08L23 46v3.04a6.033 6.033 0 0 0 3.64 5.52L30 56v7h8l-.8-2ZM42.93 44.77A8.159 8.159 0 0 1 41 45c-.23 0-.45-.02-.67-.03a6.877 6.877 0 0 1-1.39 1.98L39 47v3.65a2 2 0 0 1-1.26 1.85L34 54l2 4 2.174-1.24-.125-.186a2 2 0 0 1 .465-2.709L39.8 52.9a3 3 0 0 0 1.2-2.4V49a2 2 0 0 1 2-2v-2Z"
                    style={{
                        fill: "#714226",
                    }}
                />
                <path
                    d="M52.5 21a5.477 5.477 0 0 0-2.58.64 4.551 4.551 0 0 0-.51-.21A6.867 6.867 0 0 0 47 21a7 7 0 0 0-7 7 7.455 7.455 0 0 0 .09 1.06 7.912 7.912 0 0 0-3.67 1.38 5.991 5.991 0 0 0-11.34 1.65l-.08-.01v3l.08.01a6.033 6.033 0 0 1 1.8-3.44 5.982 5.982 0 0 1 9.54 1.79 7.912 7.912 0 0 1 3.67-1.38A7.455 7.455 0 0 1 40 31a7 7 0 0 1 7-7 6.867 6.867 0 0 1 2.41.43 4.551 4.551 0 0 1 .51.21 5.5 5.5 0 0 1 7.863 3.341A5.457 5.457 0 0 0 52.5 21Z"
                    style={{
                        fill: "#8ee05b",
                    }}
                />
                <path
                    d="M44.082 18.4a9.282 9.282 0 0 0-14.244 4.03c.038-1.346-1.436-2.2-2.748-2.506a11.075 11.075 0 0 0-6.233.4 6.848 6.848 0 0 1-1.336.477c-.514.073-1.045.01-1.566.1a7.458 7.458 0 0 0-.885.215A6.7 6.7 0 0 0 17 22a5.365 5.365 0 0 0 .08.99A6.98 6.98 0 0 0 24 29a7.071 7.071 0 0 0 2.65-.52 1.952 1.952 0 0 0 .23.17 5.982 5.982 0 0 1 9.54 1.79 7.912 7.912 0 0 1 3.67-1.38A7.455 7.455 0 0 1 40 28a7 7 0 0 1 7-7 6.867 6.867 0 0 1 2.41.43 6.975 6.975 0 0 0 2.476-4.23 7.865 7.865 0 0 0-7.804 1.2Z"
                    style={{
                        fill: "#44853b",
                    }}
                />
                <path
                    d="M50.088 11.2c-.33 0-.66-.015-.991.007a8.481 8.481 0 0 0-5.785 2.542 7.448 7.448 0 0 0-10.908.435 6.837 6.837 0 0 0-10.211 1.688 5.615 5.615 0 0 0-4.093-1.329 8.406 8.406 0 0 0-4.1 1.488 11.2 11.2 0 0 0-1.74 1.478A4.986 4.986 0 0 0 17 21c.03 0 .05-.01.08-.01-.006.041 0 .083-.01.124a7.458 7.458 0 0 1 .885-.215c.521-.09 1.052-.027 1.566-.1a6.848 6.848 0 0 0 1.336-.477 11.075 11.075 0 0 1 6.233-.4c1.312.3 2.786 1.16 2.748 2.506A9.282 9.282 0 0 1 44.082 18.4a7.865 7.865 0 0 1 7.8-1.2 6.86 6.86 0 0 0-1.8-6Z"
                    style={{
                        fill: "#52a147",
                    }}
                />
                <path
                    d="M8.081 40.838a21.553 21.553 0 0 1 .819-2.666 2.333 2.333 0 0 1-1.34-.9 3.825 3.825 0 0 1-.635-3.626 3.428 3.428 0 0 1 1.15-1.535 3.337 3.337 0 0 1 .844-.468c.2-.075.818-.108.888-.285.062-.157-.428-.856-.5-1.024a9.193 9.193 0 0 1-.46-1.213 6.415 6.415 0 0 1-.258-2.538 5.173 5.173 0 0 1 .248-1.071A4.987 4.987 0 0 0 6 30c0 .14.03.27.04.4a5 5 0 0 0 0 9.2c-.01.13-.04.26-.04.4a3.984 3.984 0 0 0 1.907 3.4 9.438 9.438 0 0 1 .174-2.562Z"
                    style={{
                        fill: "#39632c",
                    }}
                />
                <path
                    d="M26.65 28.48A7.071 7.071 0 0 1 24 29a6.98 6.98 0 0 1-6.92-6.01L17 23a4 4 0 0 0-3.688 2.482c.313 1.027.719 2.028 1.127 3.022.132.324.265.648.424.96a5.79 5.79 0 0 0 .969 1.455 8.159 8.159 0 0 0-1.12.868 6.013 6.013 0 0 0-.878 1.137 7 7 0 0 0-.928 2.7 11 11 0 0 0 .949 5.592 5.652 5.652 0 0 0-2.317 2.984A4.981 4.981 0 0 0 16 47a4.526 4.526 0 0 0 .98-.1A4.981 4.981 0 0 0 20 45l.09-.19A7 7 0 0 1 24 32a7.738 7.738 0 0 1 1.08.09 6.033 6.033 0 0 1 1.8-3.44 1.952 1.952 0 0 1-.23-.17Z"
                    style={{
                        fill: "#233d1b",
                    }}
                />
                <path
                    d="M13.855 41.22a11 11 0 0 1-.949-5.592 7 7 0 0 1 .928-2.7 6.013 6.013 0 0 1 .878-1.137 8.159 8.159 0 0 1 1.12-.868 5.79 5.79 0 0 1-.969-1.455 12.43 12.43 0 0 1-.424-.96c-.408-.994-.814-2-1.127-3.022-.013.03-.03.058-.042.088a4.817 4.817 0 0 0-4.436-.057 5.173 5.173 0 0 0-.248 1.071 6.415 6.415 0 0 0 .258 2.538 9.193 9.193 0 0 0 .46 1.213c.077.168.567.867.5 1.024-.07.177-.688.21-.888.285a3.337 3.337 0 0 0-.844.468 3.428 3.428 0 0 0-1.15 1.535 3.825 3.825 0 0 0 .635 3.626 2.333 2.333 0 0 0 1.34.9 21.553 21.553 0 0 0-.821 2.666 9.438 9.438 0 0 0-.169 2.557A3.966 3.966 0 0 0 10 44a4.178 4.178 0 0 0 1.33-.24c.059.154.136.3.208.444a5.652 5.652 0 0 1 2.317-2.984Z"
                    style={{
                        fill: "#2f5224",
                    }}
                />
                <path
                    d="M14 16.031a9 9 0 0 1 3.031-1.331A4.987 4.987 0 0 1 22 10a4.893 4.893 0 0 1 1.99.42 6.986 6.986 0 0 1 9.31-2.59 6.979 6.979 0 0 1 10.9 1.913C44.688 9.31 45 9 45 9h-.08A6.531 6.531 0 0 0 45 8a6.986 6.986 0 0 0-11.7-5.17 6.986 6.986 0 0 0-9.31 2.59A4.893 4.893 0 0 0 22 5a5 5 0 0 0-5 5 5.578 5.578 0 0 0 .1 1.01c-.03 0-.06-.01-.1-.01a5 5 0 0 0-5 5 4.859 4.859 0 0 0 .524 2.163 5.121 5.121 0 0 1-.267-.654A11.2 11.2 0 0 1 14 16.031Z"
                    style={{
                        fill: "#7bc24f",
                    }}
                />
            </g>
        </svg>
    );
}

export default TreeIcon;
