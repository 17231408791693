import React from "react";

export default React.createContext({
    setError: () => {},
    setValid: () => {},
    page: "",
    setPage: () => {},
    items: [],
    setItems: () => {},
    actions: [],
    setActions: () => {},
    filters: [],
    setFilters: () => {},
    filterValues: [],
    setFilterValues: () => {},
    pagination: [],
    setPagination: () => {},
    sortOn: "",
    setSortOn: () => {},
    sortValue: "",
    setSortValue: () => {},
    search: 0,
    setSearch: () => {},
    header: [],
    setHeader: () => {},
    list: [],
    setList: () => {},
    loading: false,
    setLoading: () => {},
    editing: false,
    setEditing: () => {},
    setOverBoxForm: () => {},
    link: () => {}
})
