import React, {useContext, useEffect} from "react";
import $ from "jquery";
import AppContext from "../../../context/AppContext";
import "../../../css/ErrorMessage.css";

const ErrorMessage = props => {
    const { setError } = useContext(AppContext);

    const cleanError = () => {
        $(".site > .error").addClass("disappear");

        setTimeout(() => {
            setError(null);
        }, 500);
    }

    useEffect(() => {
        let timeout = setTimeout(() => { cleanError() }, 3000);

        return() => {
            clearTimeout(timeout);
        }
    }, []);

    return (
        <p className={"error"}>
            {
                props.message
            }
        </p>
    )
}

export default ErrorMessage;