import React from "react";

const SettingIcon = props => {
    return (
        <svg
            height="1em"
            viewBox="0 0 512.207 512.207"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M466.104 256.049c0-4.883-.174-9.82-.52-14.754l46.624-39.918-2.277-9.018a265.273 265.273 0 0 0-12.022-36.324 265.347 265.347 0 0 0-17.176-34.17l-4.767-7.987-61.095 4.734a211.766 211.766 0 0 0-21.075-21.108l4.743-61.208-7.987-4.766a265.257 265.257 0 0 0-34.185-17.184A265.223 265.223 0 0 0 320.059 2.33L311.041.053l-39.847 46.54a210.79 210.79 0 0 0-15.089-.542l-45 44.997 45 45c66.168 0 120 53.832 120 120s-53.832 120-120 120l-45 45 45 44.998c4.911 0 9.82-.172 14.754-.518l39.918 46.624 9.018-2.277a265.273 265.273 0 0 0 36.324-12.022 265.083 265.083 0 0 0 34.169-17.177l7.987-4.766-4.734-61.095a211.659 211.659 0 0 0 21.108-21.075l61.208 4.743 4.766-7.987a265.257 265.257 0 0 0 17.184-34.185 265.223 265.223 0 0 0 12.016-36.308l2.277-9.018-46.54-39.847c.361-5.05.544-10.099.544-15.089z"
                fill="#587aa1"
            />
            <path
                d="M46.104 256.049c0-4.883.174-9.82.52-14.754L0 201.377l2.277-9.018a265.273 265.273 0 0 1 12.022-36.324 265.347 265.347 0 0 1 17.176-34.17l4.767-7.987 61.095 4.734a211.766 211.766 0 0 1 21.075-21.108l-4.743-61.208 7.987-4.766a265.257 265.257 0 0 1 34.185-17.184A265.223 265.223 0 0 1 192.149 2.33l9.018-2.277 39.847 46.54a210.79 210.79 0 0 1 15.089-.542v89.997c-66.168 0-120 53.832-120 120s53.832 120 120 120v89.998c-4.911 0-9.82-.172-14.754-.518l-39.918 46.624-9.018-2.277a265.273 265.273 0 0 1-36.324-12.022 265.083 265.083 0 0 1-34.169-17.177l-7.987-4.766 4.734-61.095a211.659 211.659 0 0 1-21.108-21.075l-61.208 4.743-4.766-7.987a265.257 265.257 0 0 1-17.184-34.185 265.223 265.223 0 0 1-12.016-36.308l-2.277-9.018 46.54-39.847a211.384 211.384 0 0 1-.544-15.089z"
                fill="#6c90b8"
            />
            <path
                d="m256.104 106.049-30 30 30 30c49.626 0 90 40.374 90 90s-40.374 90-90 90l-30 30 30 30c82.711 0 150-67.289 150-150s-67.29-150-150-150z"
                fill="#81a6c7"
            />
            <path
                d="M166.104 256.049c0-49.626 40.374-90 90-90v-60c-82.711 0-150 67.289-150 150s67.289 150 150 150v-60c-49.626 0-90-40.374-90-90z"
                fill="#97bedb"
            />
        </svg>
    );
}

export default SettingIcon;
