import React, {useContext, useEffect, useState} from "react";
import {Routes, Route, useNavigate, useLocation} from "react-router-dom";
import $ from "jquery";
import NotFound from "./404/NotFound";
import ErrorMessage from "./error/Error";
import ValidMessage from "./valid/Valid";
import Header from "./header/Header";
import StateMenu from "../state/StateMenu";
import StateTable from "../state/StateTable";
import Menu from "./menu/Menu";
import Table from "./table/Table";
import InputSwitch from "../form/input/generated/InputSwitch";
import OverBoxForm from "./box/OverBoxForm";
import AuthController from "../../stories/_auth/Auth/AuthController";
import SessionContext from "../../context/SessionContext";
import AppContext from "../../context/AppContext";
import "../../css/Site.css";

const Site = () => {
    const { handleCheckDefinePwd } = useContext(SessionContext);
    const [ error, setError ] = useState(null);
    const [ valid, setValid ] = useState(null);
    const [ page, setPage ] = useState("");
    const [ items, setItems ] = useState([]);
    const [ actions, setActions ] = useState([]);
    const [ filters, setFilters ] = useState([]);
    const [ filterValues, setFilterValues ] = useState([]);
    const [ pagination, setPagination ] = useState(null);
    const [ nbPage, setNbPage ] = useState(1);
    const [ nbPerPage, setNbPerPage ] = useState(25);
    const [ sortOn, setSortOn ] = useState(null);
    const [ sortValue, setSortValue ] = useState(null);
    const [ search, setSearch ] = useState(0);
    const [ header, setHeader ] = useState([]);
    const [ list, setList ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ editing, setEditing ] = useState(false);
    const [ overBoxForm, setOverBoxForm ] = useState(null);
    let navigation = useNavigate();
    let location = useLocation();

    const content = () => {
        switch (page) {
            case "home":
            case "settings":
            case "establishment":
            case "domains":
            case "abilities":
            case "subAbilities":
            case "validations":
                return <Menu />;
            case "users":
            case "classrooms":
            case "students":
                return <Table />;
            case "404": return <NotFound />;
            default: break;
        }
    }
    const link = (link, obj = null) => {
        if (obj !== null)
            sessionStorage.setItem(obj.object_type, JSON.stringify(obj));

        let elmtItem = ".menu > .item";
        let max = $(elmtItem).length;
        let duration = 100;

        if ($(elmtItem).length > 10)
            duration = 30;
        else if ($(elmtItem).length > 7)
            duration = 60;

        if (max > 0) {
            $(elmtItem).each(function(index) {
                setTimeout(() => { $(this).removeClass("appear"); if (index === (max - 1)) { endDisappear(link); } }, index*duration);
            });
        }
        else {
            navigation(link);
        }
    }
    const endDisappear = link => {
        setTimeout(() => { navigation(link); }, 100);
    }
    const edit = () => {
        let explodePathname = location.pathname.split('/');

        if (explodePathname <= 1)
            return;

        let editable = ["domaines"];

        return <div className={"containerEditable" + (!editable.includes(explodePathname[1]) || !AuthController.hasRules("admin", "manager") ? " disappear" : "")} onClick={() => { changeEdit("", "", !editing) }}>
            <InputSwitch classname={"switchEditable"} attribute={"edit"} returnType={"bool"} value={editing} />
            <p>Editer</p>
        </div>
    }
    const changeEdit = (attribute, returnType, value) => {
        setEditing(value);
    }

    useEffect(() => {
        handleCheckDefinePwd();
    }, []);

    const appContextValues = {
        setError,
        setValid,
        page,
        setPage,
        items,
        setItems,
        actions,
        setActions,
        filters,
        setFilters,
        filterValues,
        setFilterValues,
        pagination,
        setPagination,
        nbPage,
        setNbPage,
        nbPerPage,
        setNbPerPage,
        sortOn,
        setSortOn,
        sortValue,
        setSortValue,
        search,
        setSearch,
        header,
        setHeader,
        list,
        setList,
        loading,
        setLoading,
        editing,
        setEditing,
        setOverBoxForm,
        link
    };

    return (
        <AppContext.Provider value={appContextValues}>
            <div className={"site"}>
                {
                    error !== null
                    && <ErrorMessage message={error} />
                }
                {
                    valid !== null
                    && <ValidMessage message={valid} />
                }
                <Header page={page} />
                {
                    content()
                }
                {
                    edit()
                }
                <OverBoxForm className={overBoxForm !== null ? "appear" : ""} datas={overBoxForm} />
                <Routes>
                    <Route exact path={"/home"} element={<StateMenu state={"home"} />} />
                    <Route exact path={"/reglages"} element={<StateMenu state={"settings"} />} />
                    <Route exact path={"/ecole"} element={<StateMenu state={"establishment"} />} />
                    <Route exact path={"/utilisateurs"} element={<StateTable state={"users"} />} />
                    <Route exact path={"/classes"} element={<StateTable state={"classrooms"} />} />
                    <Route exact path={"/eleves"} element={<StateTable state={"students"} />} />
                    <Route exact path={"/domaines"} element={<StateMenu state={"domains"} />} />
                    <Route exact path={"/domaines/:id"} element={<StateMenu state={"abilities"} />} />
                    <Route exact path={"/domaines/:idDomain/competences/:id"} element={<StateMenu state={"subAbilities"} />} />
                    <Route exact path={"/carnets"} element={<StateMenu state={"validations"} />} />
                </Routes>
            </div>
        </AppContext.Provider>
    )
}

export default Site;