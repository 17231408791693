import Entity from "../../../class/abstract/Entity";
import StringTools from "../../../class/tool/StringTools";

class User extends Entity {
    object_type = "user";
    fullname = null;
    fullnameLimited = null;
    gender = null;
    firstname = null;
    lastname = null;
    email = null;
    definePwd = null;
    listRules = [];
    listEstablishments = [];
    rulesStr = null;

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined) {
                if (["firstname", "lastname"].includes(key))
                    Reflect.set(this, key, StringTools.capitalizeFirstLetter(datas[key].toLocaleLowerCase()))
                else if (["email"].includes(key))
                    Reflect.set(this, key, datas[key].toLocaleLowerCase())
                else
                    Reflect.set(this, key, datas[key])
            }
        }

        let firstname = this.firstname !== null ? this.firstname : "";
        let lastname = this.lastname !== null ? this.lastname : "";
        Reflect.set(this, "fullname", (this.getGenderPrefix() + lastname.toLocaleUpperCase() + " " + StringTools.capitalizeFirstLetter(firstname.toLocaleLowerCase())).trim());
        Reflect.set(this, "fullnameLimited", (StringTools.capitalizeFirstLetter(firstname.toLocaleLowerCase()) + " " + lastname.toLocaleUpperCase().charAt(0)).trim() + ".");
        Reflect.set(this, "rulesStr", this.buildRulesStr());
    }
    getGenderPrefix(spaceAfter = true) {
        let prefix = "";

        switch (this.gender) {
            case "MALE": prefix = "M."; break;
            case "FEMALE": prefix = "Mme"; break;
            default: break;
        }

        if (spaceAfter && prefix.length > 0)
            prefix += " ";

        return prefix;
    }
    buildRulesStr() {
        let rules = [];
        let dictionary = {
            "admin": "administrateur",
            "manager": "directeur",
            "teacher": "enseignant",
            "tutor": "parent"
        };

        for (let i in this.listRules) {
            if (!rules.includes(this.listRules[i].name)) {
                rules.push(StringTools.capitalizeFirstLetter(dictionary[this.listRules[i].name.toLocaleLowerCase()]));
            }
        }

        rules.sort();

        return rules.join(', ');
    }

    static buildStrRules(rules) {
        let strRules = "";

        if (rules.length === 0)
            return "aucun"

        if (rules.includes("|")) {
            let explodeRules = rules.split("|")

            for (let i in explodeRules) {
                if (strRules.length > 0) strRules += ", "
                strRules += this.ruleName(explodeRules[i].trim())
            }
        }
        else
            strRules = this.ruleName(rules)

        return strRules
    }
    static ruleName = rule => {
        switch (rule) {
            case "ADMIN": return "Administrateur"
            case "MANAGER": return "Directeur"
            case "TEACHER": return "Enseignant"
            case "TUTOR": return "Parent"
            default: return ""
        }
    }
}

export default User;
