import React from "react";

const CalopinIcon = props => {
    return (
        <svg
            height="1em"
            viewBox="0 0 512 512"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
            className={ props.classname !== undefined ? props.classname : "" }
            onClick={ props.click !== undefined ? props.click : () => {} }
        >
            <path
                d="M341.808 179.823v97.04c0 4.67-1.989 9.348-5.959 12.626-59.647 49.192-119.651 64.993-134.6 68.343a16.358 16.358 0 0 1-7.081 0c-10.733-2.403-44.689-11.226-85.058-34.211-15.865-9.038-32.723-20.256-49.542-34.132a16.305 16.305 0 0 1-5.959-12.626v-97.04z"
                fill="#365e7d"
            />
            <path
                d="M109.108 179.827v143.79c-15.864-9.033-32.718-20.254-49.542-34.131a16.299 16.299 0 0 1-5.956-12.625v-97.034z"
                fill="#2b4d66"
            />
            <path
                d="M341.808 179.823v45.66c-64.969 41.825-122.563 56.878-139.247 60.609-1.599.358-3.23.533-4.853.533s-3.254-.175-4.853-.533c-16.684-3.731-74.278-18.784-139.247-60.609v-45.66z"
                fill="#2b4d66"
            />
            <path
                d="M395.416 115.976v32.564c0 6.413-2.721 12.825-8.171 17.32-81.844 67.499-164.173 89.187-184.684 93.778-1.599.358-3.23.533-4.853.533s-3.254-.175-4.853-.533c-20.511-4.591-102.84-26.279-184.684-93.778C2.721 161.365 0 154.953 0 148.54v-32.564z"
                fill="#365e7d"
            />
            <path
                d="M197.708 115.976v144.196a22.23 22.23 0 0 1-4.853-.533c-20.511-4.591-102.84-26.279-184.684-93.778C2.721 161.365 0 154.953 0 148.54v-32.564z"
                fill="#2b4d66"
            />
            <path
                d="M202.558 227.065c20.511-4.586 102.846-26.276 184.688-93.778 10.893-8.984 10.893-25.641 0-34.626C305.404 31.16 223.069 9.47 202.558 4.884a22.23 22.23 0 0 0-9.7 0C172.346 9.47 90.012 31.16 8.17 98.662c-10.893 8.984-10.893 25.641 0 34.626 81.842 67.502 164.177 89.192 184.688 93.778 3.194.714 6.506.714 9.7-.001z"
                fill="#365e7d"
            />
            <path
                d="M101.08 268.647a7.725 7.725 0 0 1-7.726-7.726v-68.048c0-2.264.993-4.414 2.716-5.882l81.689-69.574a7.725 7.725 0 0 1 10.892.873 7.727 7.727 0 0 1-.872 10.892l-78.972 67.26v64.48a7.727 7.727 0 0 1-7.727 7.725z"
                fill="#e7a561"
            />
            <path
                d="M122.351 327.143c0-11.747-9.523-39.537-21.27-39.537s-21.27 27.79-21.27 39.537 9.523 21.27 21.27 21.27 21.27-9.522 21.27-21.27z"
                fill="#f6e266"
            />
            <path
                d="M101.084 348.418c-11.751 0-21.274-9.523-21.274-21.274 0-11.743 9.524-39.534 21.274-39.534-4.137 0-7.495 27.79-7.495 39.534.001 11.751 3.358 21.274 7.495 21.274z"
                fill="#ffc250"
            />
            <circle cx={101.08} cy={276.839} fill="#f6e266" r={15.918}/>
            <path
                d="M107.018 291.614a15.832 15.832 0 0 1-5.935 1.146c-8.791 0-15.92-7.129-15.92-15.92s7.129-15.912 15.92-15.912c2.101 0 4.097.406 5.935 1.146-5.856 2.347-9.985 8.075-9.985 14.766 0 6.69 4.129 12.419 9.985 14.774z"
                fill="#ffc250"
            />
            <ellipse cx={197.708} cy={115.975} fill="#f6e266" rx={27.515} ry={17.462}/>
            <path
                d="m378.264 474.027-54.322-14.382-29.226 48.007-19.625-48.79-16.061-39.909 83.548-33.615 16.061 39.909z"
                fill="#dd636e"
            />
            <path
                d="m258.998 419.132 83.573-33.591 16.046 39.922-83.573 33.591z"
                fill="#cb5769"
            />
            <path
                d="M500.395 352.414 371.41 404.311l-34.273-3.644-46.352 36.084-111.042 44.679-34.837-86.596-10.59-26.324 111.039-44.675 62.461-7.912 18.165-24.527 128.976-51.892c8.833-3.554 20.538 3.058 31.159 16.055 6.478 7.924 12.542 18.224 17.294 30.043 12.543 31.179 11.19 61.098-3.015 66.812z"
                fill="#f9f6f6"
            />
            <path
                d="M486.117 255.558c-1.849 2.046-4.009 3.595-6.457 4.587L144.906 394.834l-10.59-26.324 111.039-44.675 54.098-.217 26.527-32.222 128.976-51.892c8.835-3.555 20.54 3.057 31.161 16.054z"
                fill="#e7ecf6"
            />
            <ellipse
                cx={157.031}
                cy={424.971}
                fill="#e7ecf6"
                rx={27.741}
                ry={60.855}
                transform="rotate(-21.88 157.41 425.235)"
            />
            <path
                d="m371.409 404.307-80.625 32.44c14.216-5.713 15.569-35.632 3.027-66.812-4.463-11.075-10.073-20.828-16.096-28.535-10.92-13.989-23.194-21.251-32.358-17.563l80.625-32.44c9.164-3.688 21.437 3.575 32.358 17.563 6.023 7.707 11.633 17.46 16.096 28.535 12.542 31.18 11.188 61.1-3.027 66.812z"
                fill="#dd636e"
            />
            <path
                d="m358.34 308.96-80.625 32.44c-10.92-13.989-23.194-21.251-32.358-17.563l80.625-32.44c9.164-3.688 21.438 3.575 32.358 17.563z"
                fill="#cb5769"
            />
        </svg>
    );
}

export default CalopinIcon;
