import React, {useContext, useEffect} from "react";
import { useParams } from "react-router";
import AddIcon from "../icons/AddIcon";
import UserController from "../../stories/_account/Users/UserController";
import AppContext from "../../context/AppContext";
import StudentController from "../../stories/_people/Students/StudentController";
import ClassroomController from "../../stories/_school/Classrooms/ClassroomController";

const StateTable = props => {
    const { state } = props;
    const params = useParams();
    const { setError, setPage, setActions, setFilters, filterValues, setFilterValues, setPagination, nbPage, setNbPage, nbPerPage, setNbPerPage, sortOn, setSortOn, sortValue, setSortValue, header, search, setHeader, setList, setLoading, setOverBoxForm } = useContext(AppContext);

    const init = () => {
        setLoading(true);
        setPage(state);

        if (nbPage === null)
            setNbPage(1);

        if (nbPerPage === null)
            setNbPerPage(25);

        switch (state) {
            case "users":
                setActions([
                    {
                        type: "button",
                        label: "Ajouter un utilisateur",
                        icon: <AddIcon className={"icon"} />,
                        action: () => { newElement("user") }
                    }
                ]);
                setFilters([
                    {
                        attribute: "input",
                        label: null,
                        inputType: "text",
                        returnType: "string",
                        classnameInput: "searchBar extend",
                        placeholder: "Rechercher"
                    }
                ]);
                setFilterValues({
                    "input": ""
                });
                setHeader([
                    {
                        title: "Nom",
                        attribute: "fullname",
                        sorting: true
                    },
                    {
                        title: "Email",
                        attribute: "email",
                        sorting: false
                    },
                    {
                        title: "Rôles",
                        attribute: "rulesStr",
                        sorting: false
                    },
                    {
                        title: null,
                        attribute: null,
                        class: "openable",
                        sorting: false
                    }
                ]);

                break;
            case "students":
                setActions([
                    {
                        type: "button",
                        label: "Ajouter un élève",
                        icon: <AddIcon className={"icon"} />,
                        action: () => { newElement("student") }
                    }
                ]);
                setFilters([
                    {
                        attribute: "input",
                        label: null,
                        inputType: "text",
                        returnType: "string",
                        classnameInput: "searchBar extend",
                        placeholder: "Rechercher"
                    }
                ]);
                setFilterValues({
                    "input": ""
                });
                setHeader([
                    {
                        title: "Genre",
                        attribute: "genderFormat"
                    },
                    {
                        title: "Nom",
                        attribute: "fullname",
                        sorting: true
                    },
                    {
                        title: "Date de naissance",
                        attribute: "birthDateFormat",
                        sorting: false
                    },
                    {
                        title: null,
                        attribute: null,
                        class: "openable",
                        sorting: false
                    }
                ]);

                break;
            case "classrooms":
                setActions([
                    {
                        type: "button",
                        label: "Ajouter une classe",
                        icon: <AddIcon className={"icon"} />,
                        action: () => { newElement("classroom") }
                    }
                ]);
                setFilters([
                    {
                        attribute: "input",
                        label: null,
                        inputType: "text",
                        returnType: "string",
                        classnameInput: "searchBar extend",
                        placeholder: "Rechercher"
                    }
                ]);
                setFilterValues({
                    "input": ""
                });
                setHeader([
                    {
                        title: "Nom",
                        attribute: "name",
                        sorting: true
                    },
                    {
                        title: "Année",
                        attribute: "year",
                        sorting: false
                    },
                    {
                        title: null,
                        attribute: null,
                        class: "openable",
                        sorting: false
                    }
                ]);

                break;
            default:
                break;
        }
    }
    const research = () => {
        setLoading(true);

        switch (state) {
            case "users":
                getUsers();

                break;
            case "classrooms":
                getClassrooms();

                break;
            case "students":
                getStudents();

                break;
            default:
                break;
        }
    }
    const getUsers = () => {
        const controller = new UserController();
        controller._callback = returnGetUsers;
        controller.index(filterValues["input"], nbPage, nbPerPage, sortOn, sortValue, false);
    }
    const returnGetUsers = (list, error, pagination, status) => {
        setLoading(false);

        switch (status) {
            case 200:
                setPagination(pagination);
                setList(list);

                break;
            case 500:
            default:
                setError("Impossible de récupérer les utilisateurs");
                break;
        }
    }
    const getStudents = () => {
        const controller = new StudentController();
        controller._callback = returnGetStudents;
        controller.index(filterValues["input"], nbPage, nbPerPage, sortOn, sortValue, false);
    }
    const returnGetStudents = (list, error, pagination, status) => {
        setLoading(false);

        switch (status) {
            case 200:
                setPagination(pagination);
                setList(list);

                break;
            case 500:
            default:
                setError("Impossible de récupérer les élèves");
                break;
        }
    }
    const getClassrooms = () => {
        const controller = new ClassroomController();
        controller._callback = returnGetClassrooms;
        controller.index(filterValues["input"], nbPage, nbPerPage, sortOn, sortValue, false);
    }
    const returnGetClassrooms = (list, error, pagination, status) => {
        setLoading(false);

        switch (status) {
            case 200:
                setPagination(pagination);
                setList(list);

                break;
            case 500:
            default:
                setError("Impossible de récupérer les classes");
                break;
        }
    }
    const newElement = type => {
        setOverBoxForm({
            action: "add",
            type: type,
            object: null
        });
    }

    useEffect(() => {
        init();
    }, [state]);
    useEffect(() => {
        const timeoutSearch = setTimeout(() => {
            research()
        }, 500)

        return () => clearTimeout(timeoutSearch)
    }, [nbPage, nbPerPage, sortOn, sortValue, filterValues, search]);
}

export default StateTable;