import React, { useEffect, useState } from "react"
import Search2Icon from "../../../icons/Search2Icon"
import ToUppercase from "../../../icons/ToUppercase"

const InputText = props => {
    const {
        attribute,
        returnType,
        color,
        type,
        maxChars,
        autocomplete,
        classname,
        backgroundColor,
        classError,
        value,
        list,
        placeholder,
        autoFocus,
        readonly,
        toUppercase,
        handleChange,
        handleBlur,
        handleSubmit,
        handleSearch,
        handleSelect,
        handleFocus
    } = props
    const [ listDisplayed, setListDisplayed ] = useState(list)

    const filterList = () => {
        let listFiltred = []

        for(let index in list) {
            if (list[index].value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().includes(value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase())) {
                listFiltred.push(list[index])
            }
        }

        setListDisplayed(listFiltred)
    }
    const changeEvent = event => {
        let value = event.currentTarget.value

        if (maxChars !== undefined && value.length > maxChars) return

        if (handleChange === undefined || handleChange === null) return
        handleChange(attribute, returnType, value)
    }
    const blurEvent = () => {
        if (handleBlur === undefined || handleBlur === null) return
        handleBlur()
    }
    const focusEvent = () => {
        if (handleFocus === undefined || handleFocus === null) return
        handleFocus()
    }
    const keyDownEvent = event => {
        if (event.key === "Enter" && handleSubmit !== undefined && handleSubmit !== null) {
            event.preventDefault()
            handleSubmit()
        }
    }

    useEffect(() => {
        if (list !== undefined)
            document.querySelector('.smartList').style.width = document.querySelector('.inputText input').offsetWidth + "px"
    }, [])
    useEffect(() => {
        setListDisplayed(list)
    }, [list])
    useEffect(() => {
        filterList()
    }, [value])

    return (
        <div className={ "inputText" + ((classname !== undefined && classname.length > 0) ? " " + classname : "") }>
            <input
                type={ type !== undefined ? type : "text" }
                autoComplete={ autocomplete !== undefined ? autocomplete : "false" }
                className={ "extend " + (classError !== undefined && " " + classError) }
                value={ value !== null ? value : "" }
                placeholder={ placeholder }
                onChange={ changeEvent }
                onBlur={ blurEvent }
                onFocus={ focusEvent }
                autoFocus={ autoFocus !== null ? autoFocus : false }
                readOnly={ (readonly !== undefined && readonly) }
                onKeyDown={ keyDownEvent } />
        </div>
    );
}

export default InputText
