import React, {useContext} from "react";
import LeftArrowIcon from "../../icons/LeftArrowIcon";
import CalopinIcon from "../../icons/CalopinIcon";
import UserPin from "./UserPin";
import Domain from "../../../stories/_skill/Domains/Domain";
import AppContext from "../../../context/AppContext";
import "../../../css/Header.css";
import Ability from "../../../stories/_skill/Abilities/Ability";

const Header = () => {
    const { page, link } = useContext(AppContext);

    const goHome = () => {
        link("/home");
    }
    const goBack = () => {
        link(-1);
    }
    const title = () => {
        switch (page) {
            case "home":
                return <h1>Calopin</h1>;
            case "settings":
                return <h1>Réglages</h1>;
            case "establishment":
                return <h1>École</h1>;
            case "domains":
                return <h1>Compétences</h1>;
            case "abilities":
                let domainStorage = sessionStorage.getItem("domain");

                if (domainStorage !== null) {
                    let domain = new Domain(JSON.parse(domainStorage));
                    return <h1>{ domain.name.toLocaleLowerCase() }</h1>;
                }
                else
                    return <h1>...</h1>;
            case "subAbilities":
                let abilityStorage = sessionStorage.getItem("ability");

                if (abilityStorage !== null) {
                    let ability = new Ability(JSON.parse(abilityStorage));
                    return <h1>{ ability.name.toLocaleLowerCase() }</h1>;
                }
                else
                    return <h1>...</h1>;
            case "validations":
                return <h1>Carnet de suivi</h1>;
            case "users":
                return <h1>Utilisateurs</h1>;
            case "classrooms":
                return <h1>Classes</h1>;
            case "students":
                return <h1>Élèves</h1>;
            default: break;
        }
    }

    return (
        <header>
            {
                page !== "home"
                && <LeftArrowIcon classname={"goBack"} clickEvent={goBack} />
            }
            <div className={"containerTitle"}>
                <CalopinIcon classname={"logo"} click={goHome} />
                {title()}
            </div>
            <UserPin />
        </header>
    )
}

export default Header;