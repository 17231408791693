import Entity from "../../../class/abstract/Entity";
import StringTools from "../../../class/tool/StringTools";

class Student extends Entity {
    object_type = "student";
    fullname = null;
    gender = null;
    genderFormat = null;
    firstname = null;
    lastname = null;
    birthDateFormat = null;
    genders = {
        "FEMALE": "Fille",
        "MALE": "Garçon",
        "OTHER": "-",
    }

    constructor(datas = {}) {
        super(datas)
        this.setAttributes(datas)
    }

    setAttributes(datas) {
        let keys = Object.keys(this)
        let key = ""

        for(let i in keys) {
            key = keys[i]

            if (datas[key] !== undefined) {
                if (["firstname", "lastname"].includes(key))
                    Reflect.set(this, key, StringTools.capitalizeFirstLetter(datas[key].toLocaleLowerCase()))
                else
                    Reflect.set(this, key, datas[key])
            }
        }

        let firstname = this.firstname !== null ? this.firstname : "";
        let lastname = this.lastname !== null ? this.lastname : "";
        Reflect.set(this, "fullname", lastname.toLocaleUpperCase() + " " + StringTools.capitalizeFirstLetter(firstname.toLocaleLowerCase()));
        Reflect.set(this, "genderFormat", this.gender !== null ? this.genders[this.gender] : "");
    }
}

export default Student;
