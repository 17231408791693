import React, {useContext, useEffect} from "react";
import { useParams } from "react-router";
import AuthController from "../../stories/_auth/Auth/AuthController";
import AppContext from "../../context/AppContext";
import SettingIcon from "../icons/SettingIcon";
import SchoolIcon from "../icons/SchoolIcon";
import TreeIcon from "../icons/TreeIcon";
import LevelIcon from "../icons/LevelIcon";
import DomainController from "../../stories/_skill/Domains/DomainController";
import AbilityController from "../../stories/_skill/Abilities/AbilityController";
import AddIcon from "../icons/AddIcon";
import SubAbilityController from "../../stories/_skill/SubAbilities/SubAbilityController";
import UsersIcon from "../icons/UsersIcon";
import Establishment from "../../stories/_school/Establishments/Establishment";

const StateMenu = props => {
    const { state } = props;
    const params = useParams();
    const { setError, setPage, items, setItems, setLoading, editing, setEditing } = useContext(AppContext);

    const init = (start = true) => {
        setPage(state);
        let itemsTmp = [];

        switch (state) {
            case "home":
                /*
                if (AuthController.hasRules(["admin"])) {
                    itemsTmp.push({
                        title: "Configuration",
                        icon: <SettingIcon />,
                        link: "/reglages",
                    });
                }
                 */

                if (AuthController.hasRules(["admin", "manager"])) {
                    itemsTmp.push({
                        title: "École",
                        icon: <SchoolIcon/>,
                        link: "/ecole",
                    });
                }

                if (AuthController.hasRules(["admin", "manager", "teacher"])) {
                    itemsTmp.push({
                        title: "Arbre des compétences",
                        icon: <TreeIcon/>,
                        link: "/domaines",
                    });
                }

                if (AuthController.hasRules(["admin", "manager", "teacher", "tutor"])) {
                    itemsTmp.push({
                        title: "Carnet de suivi",
                        icon: <LevelIcon/>,
                        link: "/carnets",
                    });
                }

                setItems(itemsTmp);
                setEditing(false);

                break;
            case "settings":
                setItems(itemsTmp);
                setEditing(false);

                break;
            case "establishment":
                if (AuthController.hasRules(["admin", "manager"])) {
                    const establishment = new Establishment(JSON.parse(localStorage.getItem("establishment")))

                    itemsTmp.push({
                        id: establishment.id,
                        title: establishment.name.toLocaleLowerCase(),
                        icon: <SettingIcon />,
                        object: establishment
                    });

                    itemsTmp.push({
                        title: "Utilisateurs",
                        icon: <UsersIcon />,
                        link: "/utilisateurs",
                    });

                    itemsTmp.push({
                        title: "Classes",
                        icon: <SchoolIcon/>,
                        link: "/classes",
                    });

                    itemsTmp.push({
                        title: "Élèves",
                        icon: <TreeIcon/>,
                        link: "/eleves",
                    });
                }

                setItems(itemsTmp);
                setEditing(false);

                break;
            case "domains":
                setLoading(true);

                setTimeout(() => {
                    setItems(itemsTmp);
                    getDomains();
                }, 300);

                break;
            case "abilities":
                setLoading(true);

                setTimeout(() => {
                    setItems(itemsTmp);
                    getAbilities();
                }, 300);

                break;
            case "subAbilities":
                setLoading(true);

                setTimeout(() => {
                    setItems(itemsTmp);
                    getSubAbilities();
                }, 300);

                break;
            case "validations":


                break;
            default:
                break;
        }
    }
    const getDomains = () => {
        const controller = new DomainController();
        controller._callback = returnGetDomains;
        controller.index(null, null, null, null, null, true);
    }
    const returnGetDomains = (list, error, pagination, status) => {
        setLoading(false);

        switch (status) {
            case 200:
                let itemsTmp = [];

                for (let i in list) {
                    itemsTmp.push({
                        id: list[i].id,
                        title: list[i].name.toLocaleLowerCase(),
                        link: "/domaines/" + list[i].id,
                        object: list[i],
                        edit: AuthController.hasRules(["admin", "manager"])
                    });
                }

                if (AuthController.hasRules(["admin", "manager"])) {
                    itemsTmp.push({
                        icon: <AddIcon fill={"#FFFFFF"} />,
                        id: null,
                        title: "Ajouter un domaine",
                        link: "/domaines/nouveau",
                        object: null,
                        add: true,
                        type: "domain"
                    });
                }

                setItems(itemsTmp);

                break;
            case 500:
            default:
                setError("Impossible de récupérer les domaines");
                break;
        }
    }
    const getAbilities = () => {
        const controller = new AbilityController();
        controller._callback = returnGetAbilities;
        controller.index(params.id, null, null, null, null, null, true);
    }
    const returnGetAbilities = (list, error, pagination, status) => {
        setLoading(false);

        switch (status) {
            case 200:
                let itemsTmp = [];

                for (let i in list) {
                    itemsTmp.push({
                        id: list[i].id,
                        title: list[i].name.toLocaleLowerCase(),
                        link: "/domaines/" + params.id + "/competences/" + list[i].id,
                        object: list[i],
                        edit: AuthController.hasRules(["admin", "manager"])
                    });
                }

                if (AuthController.hasRules(["admin", "manager"])) {
                    itemsTmp.push({
                        icon: <AddIcon fill={"#FFFFFF"} />,
                        id: null,
                        title: "Ajouter une compétence",
                        link: "/domaines/" + params.id + "/competences/nouveau",
                        object: null,
                        add: true,
                        type: "ability"
                    });
                }

                setItems(itemsTmp);

                break;
            case 500:
            default:
                setError("Impossible de récupérer les compétences");
                break;
        }
    }
    const getSubAbilities = () => {
        const controller = new SubAbilityController();
        controller._callback = returnGetSubAbilities;
        controller.index(params.idDomain, params.id, null, null, null, null, null, true);
    }
    const returnGetSubAbilities = (list, error, pagination, status) => {
        setLoading(false);

        switch (status) {
            case 200:
                let itemsTmp = [];

                for (let i in list) {
                    itemsTmp.push({
                        id: list[i].id,
                        title: list[i].name.toLocaleLowerCase(),
                        link: "/domaines/" + params.idDomain + "/competences/" + params.id + "/sous-competences/" + list[i].id,
                        object: list[i],
                        edit: AuthController.hasRules(["admin", "manager"])
                    });
                }

                if (AuthController.hasRules(["admin", "manager"])) {
                    itemsTmp.push({
                        icon: <AddIcon fill={"#FFFFFF"} />,
                        id: null,
                        title: "Ajouter une sous-compétence",
                        link: "/domaines/" + params.idDomain + "/competences/" + params.id + "/sous-competences/nouveau",
                        object: null,
                        add: true,
                        type: "subAbility"
                    });
                }

                setItems(itemsTmp);

                break;
            case 500:
            default:
                setError("Impossible de récupérer les sous-compétences");
                break;
        }
    }

    useEffect(() => {
        init();
    }, [state]);
}

export default StateMenu;